const axios = require('axios');

export async function getUserIdByEmail(data) {
    try {
        const response = await axios.post(`/api/webForm/getUserIdByEmail`, data);
        return response;
    }
    catch (err) {
        console.error(err);
    }
}

export async function createAd(data) {
    try {
        const response = await axios.post(`/api/webForm/createAd`, data);
        return response;
    }
    catch (err) {
        console.error(err);
    }
}

export async function uploadPhotoToS3(data) {
    try {
        const response = await axios({
            method: "post",
            url: "/api/webForm/uploadPhotoToS3",
            data: data,
            headers: { 'Content-Type': `multipart/form-data` }
        });
        return response;
    } catch (err) {
        console.log("error in uploadPhotoToS3", err);
    }
}

export async function subscriberUserToStripeAsAdmin(userData) {
    try {
        const response = await axios.post(`/api/webForm/subscriberUserToStripeAsAdmin`, userData);
        return response;
    }
    catch (err) {
        console.error(err);
    }
}