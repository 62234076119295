import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/navbar/Navbar"
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Newsfeed from "./pages/Newsfeed"
import SinglePost from "./pages/SinglePost";
import Blogs from "./pages/Blogs";
import SingleBlogPost from "./pages/SingleBlogPost";

import WebForm from "./pages/WebForm";
import { AppContext } from "./context/AppContext";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const StripePublishableKeys = {
  _PROD_: "pk_live_51JUc5ZC72JeZuwAUWtgTDWn6LLgM7qJsycSxloKJXTzz5YlIObYtHOUEKVyRzYBGRdb4FvJQ84gGn8bqO9pRMNyz00jzZpFMKm",
  _DEV_: "pk_test_51JUc5ZC72JeZuwAUiaNaiCTuzdUBROjxpaTa9J0N63nD2Wffg0vvcHkf37Y4wrZ8aH9fBs2cAjJqx3YNY1oq9cRL00eq8xqaCl"
};

const stripePromise = loadStripe(StripePublishableKeys._PROD_);

window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  if (document.getElementById("NavFrame")) {
    if (currentScrollPos < 200) {
      document.getElementById("NavFrame").style.display = "none";
    } else {
      document.getElementById("NavFrame").style.display = "flex";
    }
  }
}

function App() {
  const [user, setUser] = React.useState(null);
  const [catalogs, setCatalogs] = React.useState({});
  const [values, setValues] = React.useState({});

  useEffect(() => {
    document.getElementById("NavFrame").style.display = "none";
  }, [])

  return (
    <AppContext.Provider value={
      {
        user,
        setUser: (user) => setUser(user),
        catalogs,
        setCatalogs: (catalogs) => setCatalogs(catalogs),
        values,
        setValues: (values) => setValues(values)
      }
    }>
      <div className="App">
        <Router>
          <div>
            <Navbar />
            <Switch>
              <Route exact path="/" component={Home} />
            </Switch>
            <Switch>
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            </Switch>
            <Switch>
              <Route exact path="/terms-of-service" component={TermsOfService} />
            </Switch>
            <Switch>
              <Route exact path="/newsfeed" component={Newsfeed} />
            </Switch>
            <Switch>
              <Route exact path="/post/:id" component={SinglePost} />
            </Switch>
            <Switch>
              <Route exact path="/blog" component={Blogs} />
            </Switch>
            <Switch>
              <Route exact path="/blog/:id/:subject" component={SingleBlogPost} />
            </Switch>
            <Switch>
              <Route exact path="/web-form" component={WebForm} />
            </Switch>
          </div>
        </Router>
      </div>
    </AppContext.Provider>
  );
}

export default App;