import React from 'react';
import './PleaseWait.css';
export default function PleaseWait({ message, onButtonClick, buttonText }) {
  return (
    <div id="container">
      <p>{message}</p>
      {
        onButtonClick &&
        <button className="actionButton" onClick={onButtonClick} type="button">{buttonText || 'Close'}</button>
      }
      
    </div>
  )
}