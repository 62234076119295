import React from 'react';
import "./Loading.css";
// https://www.davidhu.io/react-spinners/
import GridLoader from "react-spinners/GridLoader";
export const Loading = ({ message, messageColor, loaderColor, loaderSize, loading, speedMultiplier, centerOnPage }) => {
  return (
    <div className={centerOnPage ? "container centered": "container"}>
      <GridLoader size={loaderSize || 50} color={loaderColor || "#855B80"} loading={loading} speedMultiplier={speedMultiplier || 1.5} />
      <p style={{ color: messageColor || "#855B80" }}>{message || "Please wait..."}</p>
    </div>
  );
};
