import { blogsEndPoint } from '../config/config';

const axios = require('axios');

export async function getAllBlogs() {
  try {
    const response = await axios.get(`${blogsEndPoint}/api/blogs/?populate=*`);
    return response;
  }
  catch (err) {
    console.error(err);
  }
}

export async function getBlogById(id) {
  try {
    
    const response = await axios.get(`${blogsEndPoint}/api/blogs/${id}?populate=*`);
    return response;
  }
  catch (err) {
    console.error(err);
  }
}

export async function getAllBlogsBySubject(subject) {
  // https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/filtering-locale-publication.html#find-users-having-john-as-first-name
  try {
    const response = await axios.get(`${blogsEndPoint}/api/blogs?filters[subject][name][$eq]=${subject}&populate=*`);
    return response;
  }
  catch (err) {
    console.error(err);
  }
}

