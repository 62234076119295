import React from 'react';
import NewsHead from '../components/newsfeed/NewsHead/NewsHead'
import NewsCard from '../components/newsfeed/NewsCard/NewsCard'
import '../components/newsfeed/NewsCard/NewsCard.css'


function Newsfeed() {

    return (
    <div>
        < NewsHead />
        < NewsCard />
    </div>
    )
}

export default Newsfeed
