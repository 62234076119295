import React from 'react';
import './TopRibbon.css';
import androidlogo from '../../../Images/androidlogo.png';
import applelogo from '../../../Images/applelogo.png';

function TopRibbon() {
    return (

        <div id='RibbonFrame'>
            <div id="RibbonContainer">
                <p id="RibbonText">Equine Classifieds With a Conscience</p>
                <div id='BtnContainer'>
                    <div id='AndroidBtn'>
                        <img className='noSelect' id='BannerAndroidLogo' src={androidlogo} alt='android logo'></img>
                        <p className='btnText noSelect'>Coming soon to Android</p>
                    </div>
                    <div id='AppleBtn'>
                        <img className='noSelect' id='BannerAppleLogo' src={applelogo} alt='apple logo'></img>
                        <p className='btnText noSelect'>Coming soon to Apple</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopRibbon
