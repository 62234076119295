import React from 'react';
import LightBox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


export const FullImageCarousel = ({ images, onCloseRequest, photoIndex, onMovePrevRequest, onMoveNextRequest }) => {
  // const [photoIndex, setPhotoIndex] = React.useState(0);
  // if(!isOpen) return null;
  return (
    <LightBox
      mainSrc={images[photoIndex]}
      nextSrc={images[(photoIndex + 1) % images.length]}
      prevSrc={images[(photoIndex + images.length - 1) % images.length]}
      onCloseRequest={onCloseRequest}
      // onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
      // onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
      onMovePrevRequest={onMovePrevRequest}
      onMoveNextRequest={onMoveNextRequest}
    />
  )
}
