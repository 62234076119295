import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NewsHead from '../components/newsfeed/NewsHead/NewsHead';
import { getBlogById, getAllBlogsBySubject } from '../api/blogs';
import SubscribeRibbon from '../components/home/SubscribeRibbon/SubscribeRibbon';
import Footer from '../components/home/Footer/Footer';
import { Loading } from '../components/Loading/Loading';
import "../components/BlogCard/BlogCard.css";
import PageTitle from '../components/PageTitle/PageTitle';
import BlogCard from '../components/BlogCard/BlogCard';
import '../components/newsfeed/NewsHead/NewsHead.css';
import { blogsEndPoint } from '../config/config';
import moment from 'moment';



function SingleBlogPost() {
  const [blog, setBlog] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [subject, setSubject] = useState('');
  const [date, setDate] = useState('');
  const [postImage, setPostImage] = useState('');
  const [body, setBody] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  // Page will load with a url like /post/12345 (where 12345 is the post id), so we can use the useParams hook to get the id
  let { id, subject: subj } = useParams();


  useEffect(() => {
    async function getData() {
      try {
        // Get the post data from the API using the post id we just got from the url
        const response = await getBlogById(id);
        const relatedItemsBySubject = await getAllBlogsBySubject(subj);
        const blogPost = response?.data?.data;
        const relatedBlogs = relatedItemsBySubject?.data?.data;
        // Filter out the current blog from the related blogs
        const filteredRelatedBlogs = relatedBlogs.filter(item => item.id !== Number(id));
        setRelatedBlogs(filteredRelatedBlogs);
        if (blogPost) {
          setBlog(blogPost); // Set the post data to the state
          setTimeout(() => {
            // Check if we have any blog data, if not just return null
            // Get the blog id, title, subject and date, image and content
            const { title: postTitle, subject: postSubject, date: postDate, image } = blogPost?.attributes;
            const { name: blogSubject } = postSubject?.data?.attributes;
            setTitle(postTitle);
            setSubject(blogSubject);
            setDate(postDate);

            // Retrieve image formats
            const { formats } = image.data.attributes;
            // Get the large image data
            let { large: postImage } = formats;
            // if large is not available, Get the small format
            if (!postImage || postImage === undefined) {
              ({ small: postImage } = formats);
            }
            // if small is also not available, Get the small thumbnail format
            if (!postImage || postImage === undefined) {
              ({ thumbnail: postImage } = formats);
            }
            setPostImage(postImage);
            // Get the blog body...which should come in as an array for each paragraph since we are indenting the paragraphs
            const { body } = blogPost?.attributes;
            setBody(body);
            setIsLoading(false);
          }, 1000);
        } else {
          setBlog(null);
          setIsLoading(false);
        }
      } catch (error) {
        console.log({ error });
        setBlog(null);
        setIsLoading(false);
      }
    }
    getData();
  }, [id, subj]);

  return (
    <main>
      <NewsHead />
      <section id="blogsWrapper">
        {
          blog && !isLoading ?
            <div id="blogPostWrapper">
              <img src={postImage.url} alt={title} className="blogPostImage" />
              <div id="blogPostContent">
                <a aria-label="Click to go back to all the posts" id="backToBlock" href="/blog">
                  <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                  Back to blogs
                </a>
                <h1 id="postTitle">{title}</h1>
                <div className='blogPostDateAndSubject'>
                  <span>{moment(date).format('MM/DD/YYYY')}</span>
                  <span>{subject}</span>
                </div>
                <div className='postBlogBody'>
                  {
                    body.map((item) => <p key={item.id} className="blogPostParagraph">{item.text}</p>)
                  }
                </div>
                {
                  relatedBlogs.length > 0 &&
                  <>
                    <PageTitle title="Related Articles" isScaledDown />
                    <div id="relatedArticlesWrapper">
                      {
                        relatedBlogs.map((item) => <BlogCard key={item.id} blog={item} />)

                      }
                    </div>
                  </>
                }

              </div>
            </div> :
            <Loading />
        }

      </section>

      <SubscribeRibbon />
      <Footer />
    </main>
  )
}

export default SingleBlogPost;