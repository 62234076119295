import React, { useState } from 'react';
import './OurApp.css';
import StayOrganized from '../../../Images/stayorganized.png';
import StayOrganizedSel from '../../../Images/stayorganizedselected.png';
import FindTheHorseOfYourDreams from '../../../Images/findthehorseofyourdreams.png';
import FindTheHorseOfYourDreamsSel from '../../../Images/findthehorseofyourdreamsselected.png';
import CompetitivePricing from '../../../Images/competitivepricing.png';
import CompetitivePricingSel from '../../../Images/competitivepricingselected.png';
import ExcitingNewFeatures from '../../../Images/excitingnewfeatures.png';
import ExcitingNewFeaturesSel from '../../../Images/excitingnewfeaturesselected.png';
import StayOrganizedImg from '../../../Images/stayorganizedimg.png';
import FindtheHorseofYourDreamsImg from '../../../Images/findthehorseofyourdreamsimg.png';
import CompetitivePricingImg from '../../../Images/competitivepricingimg.png';
import MoretoCome from '../../../Images/moretocome.png';

function OurApp() {

    const [selectedNum, setSelectedNum] = useState(0)

    return (
        <div id='OurAppFrame'>
            <div id='OurAppContainer'>
                <div id='OurAppTop'>
                    <div id='OurAppHead'></div>
                    <div id='OurAppText'>Our App</div>
                </div>
                <div id="OurAppContentContainer">
                    <div id='OurAppSubheads' className='noSelect'>
                        <div className={selectedNum === 0 ? 'SubheadBoxActive' : 'SubheadBoxes'} onClick={() => { setSelectedNum(0) }}>
                            <img id='StayOrganizedImg' src={selectedNum === 0 ? StayOrganizedSel : StayOrganized} alt='Stay Organized png'></img>
                            <p className={selectedNum === 0 ? 'ActiveTxt BaseSubheadTxt' : 'BaseSubheadTxt'} >Stay Organized</p>
                        </div>
                        <div className={selectedNum === 1 ? 'SubheadBoxActive' : 'SubheadBoxes'} onClick={() => { setSelectedNum(1) }}>
                            <img id='FindTheHorseOfYourDreamsImg' src={selectedNum === 1 ? FindTheHorseOfYourDreamsSel : FindTheHorseOfYourDreams} alt='Find the Horse of Your Dreams png'></img>
                            <p className={selectedNum === 1 ? 'ActiveTxt BaseSubheadTxt' : 'BaseSubheadTxt'}>Find the Horse of Your Dreams</p>
                        </div>
                        <div className={selectedNum === 2 ? 'SubheadBoxActive' : 'SubheadBoxes'} onClick={() => { setSelectedNum(2) }}>
                            <img id='CompetitivePricingImg' src={selectedNum === 2 ? CompetitivePricingSel : CompetitivePricing} alt='Competitive Pricing png'></img>
                            <p className={selectedNum === 2 ? 'ActiveTxt BaseSubheadTxt' : 'BaseSubheadTxt'}>Competitive Pricing</p>
                        </div>
                        <div className={selectedNum === 3 ? 'SubheadBoxActive' : 'SubheadBoxes'} onClick={() => { setSelectedNum(3) }}>
                            <img id='ExcitingNewFeaturesImg' src={selectedNum === 3 ? ExcitingNewFeaturesSel : ExcitingNewFeatures} alt='Exciting New Features png'></img>
                            <p className={selectedNum === 3 ? 'ActiveTxt BaseSubheadTxt' : 'BaseSubheadTxt'}>Exciting New Features</p>
                        </div>
                    </div>
                    <div className="OurAppMobleSubHeadDiv">
                        <p>
                        {selectedNum === 0 ?
                            'Stay Organized'
                            : selectedNum === 1 ?
                                'Find the Horse of Your Dreams'
                                : selectedNum === 2 ?
                                    'Competitive Pricing'
                                    :
                                    'Exciting New Features'
                        }
                        </p>
                    </div>
                    <div id='OurAppBody'>
                        <div id={
                            selectedNum === 0 ? 'StayOrganizedBody'
                                : selectedNum === 1 ? 'FindtheHorseofYourDreamsBody'
                                    : selectedNum === 2 ? 'CompetitivePricingBody'
                                        : 'ExcitingNewFeaturesBody'
                        }>
                            <div id='BodyTxtContainer'>
                                <div id='TxtCenter'>
                                    {selectedNum === 0 ?
                                        <p className='OurAppBodyTxt'>
                                            Easily view, search, filter, follow, and message all in app.
                                        </p>
                                        :
                                        selectedNum === 1 ?
                                            <p className='OurAppBodyTxt'>
                                                Whether you are buying or selling, we connect you to your dream horse!
                                            </p>
                                            :
                                            selectedNum === 2 ?
                                                <p className='OurAppBodyTxt'>
                                                Industry leading pricing with an unlimited ad option!
                                                </p>
                                                :
                                                <p className='OurAppBodyTxt'>
                                                    We are committed to continual improvement with future and ongoing development and exciting new features planned.
                                                </p>
                                    }
                                </div>
                            </div>
                            <div id='BodyImgContainer'>
                                <img className='OurAppImg noSelect'
                                    src={selectedNum === 0 ? StayOrganizedImg
                                        : selectedNum === 1 ? FindtheHorseofYourDreamsImg
                                            : selectedNum === 2 ? CompetitivePricingImg
                                                : MoretoCome
                                    } alt="Our App Display Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurApp
