import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NewsHead from '../components/newsfeed/NewsHead/NewsHead';
import { getAllBlogs } from '../api/blogs';
import SubscribeRibbon from '../components/home/SubscribeRibbon/SubscribeRibbon';
import Footer from '../components/home/Footer/Footer';
import { Loading } from '../components/Loading/Loading';
import "../components/BlogCard/BlogCard.css";
import PageTitle from '../components/PageTitle/PageTitle';
import BlogCard from '../components/BlogCard/BlogCard';
import '../components/newsfeed/NewsHead/NewsHead.css';

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  // Page will load with a url like /post/12345 (where 12345 is the post id), so we can use the useParams hook to get the id
  let { id } = useParams();

  useEffect(() => {
    async function getData() {
      try {
        // Get the post data from the API using the post id we just got from the url
        const response = await getAllBlogs();
        
        const allBlogs = response?.data?.data;
        // console.log({allBlogs})
        if (allBlogs) {
          setBlogs(allBlogs); // Set the post data to the state
        } else {
          setBlogs({});
        }
      } catch (error) {
        setBlogs({});
      }
    }
    getData();
  }, [id])

  return (
    <main>
      <NewsHead />
      <section id="blogsWrapper">
        <div id="contentWrapper">
          <PageTitle title="Blog" />
          {
            blogs.length > 0 ?
              blogs.map((blog, index) => <BlogCard key={blog.id} blog={blog} />)
              :
              <Loading />
          }
        </div>
      </section>

      <SubscribeRibbon />
      <Footer />
    </main>
  )
}

export default Blogs;