import React from 'react'
import logo from '../../../Images/eqlogo.png'
import './NewsHead.css'
// import hamburger from '../../../Images/hamburger.png' 
import useWindowDimensions from '../../../hooks/UseWindowDimensions'
import Hamburger from '../../shared/Hamburger';


function NewsHead() {
    const { width } = useWindowDimensions();

    return (
        <div id='NewsNavFrame'>
            <div id='NewsNavContainer'>
                <a href="/" id='NewsNavBrandContainer'>
                    <div id='NavLogoContainer'>
                        <img id='NavLogo' src={logo} alt='EQ logo'></img>
                    </div>
                    <p id='NavTitle'>Equinoble</p>
                </a>
                {width < 420 ?
                    <div id='LinkContainer'>
                        <Hamburger />
                    </div>
                    :
                    <div id='LinkContainer'>
                        <a id='HomeLink' className='NavText' href='/'>Home</a>
                        <a id='NewsLink' className='NavText' href='/newsfeed'>Newsfeed</a>
                        <a id='NewsLink' className='NavText' href='/blog'>Blog</a>
                    </div>
                }
            </div>
        </div>
    )
}

export default NewsHead
