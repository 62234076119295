import React, { useRef, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { AppContext } from '../../context/AppContext';
import PleaseWait from '../PleaseWait/PleaseWait';
import { createAd, uploadPhotoToS3, subscriberUserToStripeAsAdmin } from "../../api/webForm";

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const initialValues = {
  "title": "",
  "description": "",
  "ss": "",
  "br1": "",
  "br1_id": null,
  "br2": [],
  "br2_id": null,
  "age": "",
  "di1": "Discipline - primary",
  "di1_id": null,
  "di2": [],
  "di2_id": null,
  "height": "",
  "gender": "",
  "gender_id": null,
  "price": "",
  "name": "",
  "tp": "Temperament",
  "tp_id": null,
  "clr": "Color",
  "clr_id": null,
  "zipCode": "",
  "location": "",
  "cnt": "Will a sale contrat be required?",
  "cnt_id": false,
  "ra": "Rescue/Adoption",
  "ra_id": false
};

export default function PostForm({
  setShowPostForm,
  setShowUserForm,
  resetUserForm,
  email,
  userId,
  userData
}) {

  let { values, setValues, catalogs, setCatalogs } = useContext(AppContext);
  const [postType, setPostType] = useState('');
  const [postTitle, setPostTitle] = useState('');
  const [photoURL, setPhotoURL] = useState(null);
  const [photoFile, setPhotoFile] = useState(null);
  const [photoURLs, setPhotoURLs] = useState(null);
  const [photoFiles, setPhotoFiles] = useState(null);
  const [description, setDescription] = useState('');
  const [postName, setPostName] = useState('');
  const [postPrice, setPostPrice] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [isForRescueOrAdoption, setIsForRescueOrAdoption] = useState(false);
  const [requireContract, setRequireContract] = useState(false);
  const [allPosts, setAllPosts] = useState([]);
  const [zipCode, setZipCode] = useState('');
  const [age, setAge] = useState('');
  const [height, setHeight] = useState('');
  const [temperament, setTemperament] = useState('');
  const [errors, setErrors] = useState({});
  const [isResetting, setIsResetting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isCreatingAd, setIsCreatingAd] = useState(false);
  const [isProcessingForm, setIsProcessingForm] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [showStripeCard, setShowStripeCard] = useState(false);
  const [showAllPosts, setShowAllPosts] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);


  const imageRef = useRef(null);
  // const stripe = useStripe();
  // const elements = useElements();

  const animatedComponents = makeAnimated();

  useEffect(() => {
    const formatCatalog = () => {
      // Loop through object of arrays and add keys to each value
      let catalog = {};
      for (let key in catalogs) {
        catalog[key] = catalogs[key].map(item => {
          return {
            value: item.id,
            label: item.name,
            id: item.id,
            name: item.name
          }
        });
      }
      setCatalogs(catalog);
    }
    setValues(initialValues);
    formatCatalog();
    createAgeAndTemperament();
    calculateHorseHeight();

    return () => {
      // TODO: Clean up by removing all photos that are not in the database from S3
      // console.log('delete photos from S3 if');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createAgeAndTemperament = () => {
    let ageArray = [];
    let temperamentArray = [];
    // create age array of object from 1 to 10 for select
    for (let i = 1; i <= 31; i++) {
      ageArray.push({ value: i, label: i, name: i, id: i });
    }
    // create temperament array of object from 1 to 10 for select
    for (let i = 1; i <= 10; i++) {
      temperamentArray.push({ value: i, label: i, name: i, id: i });
    }
    setAge(ageArray);
    setTemperament(temperamentArray);
  }

  const calculateHorseHeight = () => {
    // create an array of horses height
    const heights = [];
    for (let i = 1; i < 101; i++) {
      heights.push(i);
    }
    const allHeights = [];
    heights.forEach(height => {
      let newHeight = Math.floor((height) / 4) + ((height % 4) / 10);
      allHeights.push({
        label: newHeight,
        value: newHeight,
        id: newHeight,
        name: newHeight
      })
    });
    setHeight(allHeights);
  }

  const onChangeText = (e) => {
    if (e.target.name === "postType") {
      setPostType(e.target.defaultValue);
      selectValue('postType', false, { id: e.target.value, name: e.target.value });
    } else if (e.target.name === "title") {
      setPostTitle(e.target.value);
      selectValue('title', false, { id: e.target.value, name: e.target.value });
    } else if (e.target.name === "postName") {
      setPostName(e.target.value);
      selectValue('postName', false, { id: e.target.value, name: e.target.value });
    } else if (e.target.name === "postPrice") {
      setPostPrice(e.target.value);
      selectValue('postPrice', false, { id: e.target.value, name: e.target.value });
    } else if (e.target.name === "isForRescueOrAdoption") {
      setIsForRescueOrAdoption(e.target.checked);
      selectValue('ra', false, { id: e.target.checked, name: e.target.checked });
    } else if (e.target.name === "requireContract") {
      setRequireContract(e.target.checked);
      selectValue('cnt', false, { id: e.target.checked, name: e.target.checked });
    } else if (e.target.name === "description") {
      setDescription(e.target.value);
      selectValue('description', false, { id: e.target.value, name: e.target.value });
    } else if (e.target.name === "city") {
      setCity(e.target.value);
      setZipCode(`${e.target.value}, ${state.toUpperCase()}`);
      selectValue('zip', false, { id: `${e.target.value}, ${state.toUpperCase()}`, name: `${e.target.value}, ${state.toUpperCase()}` });
    } else if (e.target.name === "state") {
      setState(e.target.value);
      setZipCode(`${city}, ${e.target.value.toUpperCase()}`);
      selectValue('zip', false, { id: `${city}, ${e.target.value.toUpperCase()}`, name: `${city}, ${e.target.value.toUpperCase()}` });
    }
  };

  
  const onImageChange = (e) => {
    var files = imageRef.current.files;
    const listOfFiles = [];
    const objectUrls = [];
    Array.from(files)
      .forEach(file => {
        listOfFiles.push(file)
        objectUrls.push(URL.createObjectURL(file))
      });
    setPhotoFiles(listOfFiles);
    setPhotoURLs(objectUrls);
  };

  const uploadPhoto = async (formData) => {
    const response = await uploadPhotoToS3(formData);
    return response;
  }

  const createPost = async (e) => {
    setIsSubscribing(true);
    e.preventDefault();
    const errorMessage = await validatePost();
    if (errorMessage) {
      setIsSubscribing(false);
      setErrorMessage(errorMessage);
      return;
    }

    let obj = {
      userID: userId,
      name: values.name,
      title: values.title,
      description: values.description,
      type: values.ss,
      breed: values.br1_id,
      breedSecondary: values.br2_id,
      location: values.location,
      coordinates: { lat: 0, lng: 0 },
      height: parseFloat(values.height),
      price: parseFloat(values.price),
      age: parseInt(values.age),
      gender: values.gender_id,
      discipline: values.di1_id,
      disciplineSecondary: values.di2_id,
      registered: true,
      temperament: values.tp_id,
      color: values.clr_id,
      contractRequired: values.cnt_id,
      adoption: values.ra_id,
    };

    // let photoUri = '';
    let photoUris = [];
    if (photoURLs && photoFiles) {
      // Upload photo to server
      photoFiles.forEach(async file => {
        const formData = new FormData();
        formData.append('file', file);
        try {
          const uploadResponse = await uploadPhoto(formData);
          photoUris.push(uploadResponse.data.data.url);
        } catch (error) {
          console.log('Error uploading photo', error);
        }
      });
    }

    obj.photoFiles = photoUris;
    // return false;
    let payload = [];
    if (allPosts.length > 0) {
      payload = [...allPosts, obj];
    } else {
      payload = [obj];
    }
    setAllPosts(payload);
    // const numberOfPosts = payload.length;

    // Do the Stripe Subscription first before creating the posts
    const subscriptionData = await subscribeUserInStripe();
    if (!subscriptionData.id) {
      setIsSubscribing(false);
      setIsProcessingForm(false);
      setErrorMessage('Error subscribing user. Please try again later.');
      return;
    }
    setIsSubscribing(false);
    setIsCreatingAd(true);
    setIsProcessingForm(true);

    // Create the post
    try {
      const data = await createAd(payload);
      setIsCompleted(true);
    } catch (error) {
      setIsCreatingAd(false);
      setIsProcessingForm(false);
      setIsCompleted(false);
      alert('There was an error creating your post, please try again');
      console.log('Error creating post', error);
    }
  }

  const initiateReset = () => {
    setIsCreatingAd(false);
    setIsProcessingForm(false);
    setIsCompleted(true);
    resetUserForm();
  }

  const addMorePosts = async (e) => {
    e.preventDefault();
    const errorMessage = await validatePost();
    if (errorMessage === '') {
      let obj = {
        userID: userId,
        name: values.name,
        title: values.title,
        description: values.description,
        type: values.ss,
        breed: values.br1_id,
        breedSecondary: values.br2_id,
        location: values.location,
        coordinates: { lat: 0, lng: 0 },
        height: parseFloat(values.height),
        price: parseFloat(values.price),
        age: parseInt(values.age),
        gender: values.gender_id,
        discipline: values.di1_id,
        disciplineSecondary: values.di2_id,
        registered: true,
        temperament: values.tp_id,
        color: values.clr_id,
        contractRequired: values.cnt_id,
        adoption: values.ra_id,
      };
      

      // let photoUri = '';
      let photoUris = [];
      if (photoURLs && photoFiles) {
        // Upload photo to server
        photoFiles.forEach(async file => {
          const formData = new FormData();
          formData.append('file', file);
          try {
            const uploadResponse = await uploadPhoto(formData);
            photoUris.push(uploadResponse.data.data.url);
            // photoUri = uploadResponse.data.data.url;
            // obj.photoFile = photoUri;
          } catch (error) {
            console.log('Error uploading photo', error);
          }
        });
      }
      obj.photoFiles = photoUris;
      setAllPosts(allPosts.concat(obj));
      reset();
    } else {
      setErrorMessage(errorMessage);
      console.log('updated errors', errorMessage);
    };

  }

  const validatePost = async () => {
    let newErrors = {};
    let errorMessage = '';
    if (!values.ss) {
      newErrors.postType = 'Please select a post type';
    } else if (!values.title) {
      newErrors.title = 'Please enter a title';
    } else if (!values.description) {
      newErrors.description = 'Please enter a description';
    } else if (!values.zipCode) {
      newErrors.zip = 'Please enter a city and state';
    } else if (!values.age) {
      newErrors.age = 'Please select age';
    } else if (!values.br1_id) {
      newErrors.breed = 'Please select a breed';
    } else if (!values.di1_id) {
      newErrors.city = 'Please select a discipline';
    } else if (!values.gender) {
      newErrors.breed = 'Please select a gender';
    } else if (!values.tp_id) {
      newErrors.temperament = 'Please select a temperament';
    } else if (!values.clr_id) {
      newErrors.color = 'Please select a color';
    } else if (!values.height) {
      newErrors.height = 'Please select a height';
    } else if (!values.name) {
      newErrors.postName = 'Please enter a name';
    } else if (!values.price || values.price < 0 || isNaN(values.price)) {
      newErrors.postPrice = 'Please enter a valid price';
    }

    setErrors({ ...newErrors });
    if (Object.keys(newErrors).length > 0) {
      // set error message from newErrors
      Object.keys(newErrors).forEach(key => {
        errorMessage += `${newErrors[key]}\n`;
      });
      return errorMessage;
    }
    return errorMessage;
  }

  const reset = () => {
    setIsResetting(true);
    setValues(initialValues);
    setPostType('');
    setPostTitle('');
    setPhotoURLs(null);
    setDescription('');
    setPostName('');
    setPostPrice('');
    setCity('');
    setState('');
    setIsForRescueOrAdoption(false);
    setRequireContract(false);
    setZipCode('');
    setErrors({});
    setErrorMessage('');
    setTimeout(() => {
      setIsResetting(false);
    }, 1000);
  }


  const selectValueTest = (value) => {
    // const finalObject = {
    //   "title": "Sfssf",
    //   "description": "Sfsdf",
    //   "ss": "selling",
    //   "br1": "Albanian",
    //   "br1_id": "Uv4krHyipa7ArK1H2luQ",
    //   "br2": [
    //     "8ejLUTv3Fedw7KNPYuJD",
    //     "DFuhGDZ7RudzBxw2iTFs",
    //     "oTgN6K1iF5LHm4jMl10c"
    //   ],
    //   "br2_id": null,
    //   "age": 12,
    //   "di1": "Barrel Racing",
    //   "di1_id": "nKqzezPVRpEKtYRT44ui",
    //   "di2": [
    //     "HguT9DHH53E4SNNEpj5p",
    //     "9CMEhYhXLabM3LVtloy8"
    //   ],
    //   "di2_id": null,
    //   "height": "Height",
    //   "gender": "Stallion",
    //   "gender_id": "BMhbyYEd9iG7Hxlw3L4g",
    //   "price": "Price",
    //   "name": "Horses Name",
    //   "tp": 5,
    //   "tp_id": 5,
    //   "clr": "Cremello",
    //   "clr_id": "OQL347ULY3JqKH0ySobJ",
    //   "zipCode": "Somerset, NJ",
    //   "location": "Somerset, NJ",
    //   "cnt": "Will a sale contrat be required?",
    //   "cnt_id": false,
    //   "ra": "Rescue/Adoption",
    //   "ra_id": false
    // }
  }
  const selectValue = (type, isMultiSelect, selectedItems) => {
    // Get name and id of selected item
    let name = '', id = '';
    if (isMultiSelect) {
      let selectedItemsArray = [];
      selectedItems.forEach(item => {
        selectedItemsArray.push(item.id);
      });
      name = selectedItemsArray;
      id = selectedItemsArray;
    } else {
      name = selectedItems.name;
      id = selectedItems.id;
    }
    let tempErrors = errors;
    let tempValues = { ...values };

    switch (type) {

      case 'postType':
        delete tempErrors.ss;
        tempValues.ss = name
        break;
      case 'title':
        delete tempErrors.title;
        tempValues.title = name
        break;
      case 'description':
        delete tempErrors.description;
        tempValues.description = name
        break;
      case 'breed1':
        delete tempErrors.br1;
        tempValues.br1 = name
        tempValues.br1_id = id
        break;
      case 'breed2':
        delete tempErrors.br2;
        tempValues.br2_id = id
        break;
      case 'age':
        delete tempErrors.age;
        tempValues.age = id
        break;
      case 'dis1':
        delete tempErrors.di1;
        tempValues.di1 = name
        tempValues.di1_id = id
        break;
      case 'dis2':
        delete tempErrors.di2;
        tempValues.di2_id = id
        break;
      case 'gender':
        delete tempErrors.gender;
        tempValues.gender = name
        tempValues.gender_id = id
        break;
      case 'temperament':
        delete tempErrors.tp;
        tempValues.tp = name
        tempValues.tp_id = id
        break;
      case 'color':
        delete tempErrors.clr;
        tempValues.clr = name
        tempValues.clr_id = id
        break;
      case 'zip':
        delete tempErrors.zipCode;
        tempValues.zipCode = id
        tempValues.location = id
        break;
      case 'height':
        delete tempErrors.height;
        tempValues.height = id
        break;
      case 'postName':
        delete tempErrors.postName;
        tempValues.name = id
        break;
      case 'postPrice':
        delete tempErrors.postPrice;
        tempValues.price = id
        break;
      case 'ra':
        delete tempErrors.ra_id;
        tempValues.ra_id = id
        break;
      case 'cnt':
        delete tempErrors.cnt_id;
        tempValues.cnt_id = id
        break;
      default:

    }
    setValues({ ...tempValues });
    setErrors(tempErrors);
  }

  // const subscribeUserInStripe = async () => {

  //   // // create a payment intent on the server and get a client_secret of that payment intent
  //   // userData['initStripeFirst'] = true; // when this is true, the backend will only return the client_secret
  //   // const { data } = await subscriberUserToStripeAsAdmin(userData);
  //   // const clientSecret = data.response.data;

  //   // Get a reference to the Stripe element
  //   const cardElement = elements.getElement(CardElement);
  //   if (!cardElement) {
  //     console.log('No card element', cardElement);
  //     return false;
  //   }


  //   // Create a payment method and get the id
  //   const { paymentMethod } = await stripe.createPaymentMethod({
  //     type: 'card',
  //     card: cardElement,
  //     billing_details: {
  //       name: `${userData.firstName} ${userData.lastName}`,
  //       email: userData.email,
  //     },
  //   });

  //   const paymentMethodId = paymentMethod.id;
  //   let subscriptionData = null;
  //   // Send this payment method id to the server and attach it to the user so they can be subscribed
  //   if (paymentMethodId) {
  //     userData['initStripeFirst'] = false;
  //     userData['paymentMethodId'] = paymentMethodId;
  //     // Finish subscription process
  //     try {
  //       const { data } = await subscriberUserToStripeAsAdmin(userData);

  //       if (data.response.data.id) {
  //         subscriptionData = data.response.data;
  //         return subscriptionData;
  //       }
  //       // console.log({ data })
  //     } catch (error) {
  //       console.log('There was an error subscribing the user to stripe', error);
  //       return false;
  //     }
  //   } else {
  //     console.log('There was an error subscribing the user to stripe');
  //     return false;
  //   }

  //   return subscriptionData;
  // }
  const subscribeUserInStripe = async () => {

    const paymentMethodId = "NOT_NEEDED";
    let subscriptionData = null;
    // Send this payment method id to the server and attach it to the user so they can be subscribed
    if (paymentMethodId) {
      userData['initStripeFirst'] = false;
      userData['paymentMethodId'] = paymentMethodId;
      // Finish subscription process
      try {
        const { data } = await subscriberUserToStripeAsAdmin(userData);
        if (data.response.data.id) {
          subscriptionData = data.response.data;
          return subscriptionData;
        }

      } catch (error) {
        console.log('There was an error subscribing the user to stripe', error);
        return false;
      }
    } else {
      console.log('There was an error subscribing the user to stripe');
      return false;
    }

    return subscriptionData;
  }

  return (
    <div>
      <div id="rectangleElement" />
      <p id="sectionTitle">Create post</p>
      {
        !isResetting && !isProcessingForm &&
        <div className="numberOfPostsInfo">
          <div className="postHeaderWrapper">
            <div style={{ flex: 1 }}>
              <h1>#{allPosts.length + 1}</h1>
              <p>You are about to create post #{allPosts.length + 1} for <b><em>{email}</em></b></p>
            </div>
            {
              allPosts && allPosts.length > 0 &&
              <button
                onClick={() => setShowAllPosts(!showAllPosts)}
                type="button"
                className="toggleViewAllPosts"
              >
                {showAllPosts ? 'Hide all posts' : 'View all posts'}
              </button>
            }

          </div>
          {
            showAllPosts &&
            <div className="listOfPostsWrapper">
              {
                allPosts && allPosts.length > 0 &&
                allPosts.map((post, index) => {
                  return (
                    <div className="postDetailsWrapper" key={index}>
                      <h1>#{index + 1}</h1>
                      <div>
                        <p>Title: <span>{post.title}</span></p>
                        <p>Age: <span>{post.age}</span></p>
                        <p>Price: <span>${post.price}</span></p>
                        <p>Height: <span>${post.height}</span></p>
                        <p>Contract Required: <span>${post.contractRequired ? "Yes" : "No"}</span></p>
                        <p>Type: <span className="type">{post.type}</span></p>
                        <p>Description: <span>{post.description}</span></p>
                      </div>
                    </div>
                  )
                }
                )
              }
            </div>
          }
        </div>
      }

      {
        !isCreatingAd ?
          <form id="webForm" encType="multipart/form-data">
            {
              !isResetting ?
                <>
                  <div className="form-group">
                    <p className="userTypeHeader">Post Type</p>
                    <input
                      type="radio"
                      className="form-control"
                      id="selling"
                      value="selling"
                      checked={postType === 'selling'}
                      name="postType" onChange={e => onChangeText(e)}
                    /><label htmlFor="selling">Selling</label><br />
                    <input
                      type="radio"
                      className="form-control"
                      id="searching"
                      value="searching"
                      checked={postType === 'searching'}
                      name="postType" onChange={e => onChangeText(e)}
                    /><label htmlFor="searching">Searching</label><br />
                    <input
                      type="radio"
                      className="form-control"
                      id="leasing"
                      value="leasing"
                      checked={postType === 'leasing'}
                      name="postType" onChange={e => onChangeText(e)}
                    /><label htmlFor="business">Leasing</label>
                  </div>

                  <div className="divider" />

                  <div className="form-group">
                    <label htmlFor="photo">Image</label><br />
                    <input ref={imageRef} onChange={onImageChange} type="file" multiple className="form-control" id="photo" name="photo" /><br />
                    {/* {photoURL && <img alt="Horse" src={photoURL} style={{ width: 150, height: 150 }} />} */}
                    {
                      photoURLs && photoURLs.length > 0 &&
                      <button
                        type="button"
                        aria-label={showImages ? "Hide all the selected images" : "Show all the selected images"} className="showHideImageBtn"
                        onClick={() => setShowImages(!showImages)}
                      >
                        {showImages ? "Hide" : "Show"}  {photoURLs && photoURLs.length > 1 ? "images" : "image"}
                      </button>
                    }
                    {
                      showImages && photoURLs && photoURLs.length &&
                      photoURLs.map(photoUrl => <img key={photoUrl} alt="Horse" className="imageStyle" src={photoUrl} style={{ width: 800, margin: 15, resizeMode: 'contain' }} />)
                    }
                  </div>
                  <div className="form-group">
                    <label htmlFor="title">Title</label><br />
                    <input type="text" className="form-control" id="title" name="title" value={postTitle} onChange={e => onChangeText(e)} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Description</label><br />
                    <textarea cols='10' type="text" className="form-control" id="description" name="description" value={description} onChange={e => onChangeText(e)}></textarea>
                  </div>
                  <div className="cityState">
                    <div className="form-group">
                      <label htmlFor="city">City</label><br />
                      <input type="text" className="form-control" id="city" name="city" value={city} onChange={e => onChangeText(e)} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="state">State</label><br />
                      <input style={{ textTransform: 'capitalize' }} maxLength='2' type="text" className="form-control" id="state" name="state" value={state} onChange={e => onChangeText(e)} />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="age">Age</label><br />
                    <Select placeholder="" id="age" options={age} onChange={(e) => selectValue('age', false, e)} />
                  </div>

                  <div className="form-group">
                    <label htmlFor="breed1">Breed</label><br />
                    <Select placeholder="" id="breed1" options={catalogs.breeds} onChange={(e) => selectValue('breed1', false, e)} />
                  </div>

                  <div className="form-group">
                    <label htmlFor="breed2">Breed -Secondary</label><br />
                    <Select
                      placeholder=""
                      id="breed2"
                      options={catalogs.breeds}
                      isMulti
                      components={animatedComponents}
                      onChange={(e) => selectValue('breed2', true, e)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="dis1">Discipline - Primary</label><br />
                    <Select
                      placeholder=""
                      id="dis1"
                      options={catalogs.disciplines}
                      components={animatedComponents}
                      onChange={(e) => selectValue('dis1', false, e)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="dis2">Discipline - Secondary</label><br />
                    <Select
                      placeholder=""
                      id="dis2"
                      options={catalogs.disciplines}
                      isMulti
                      components={animatedComponents}
                      onChange={(e) => selectValue('dis2', true, e)}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="gender">Gender</label><br />
                    <Select
                      placeholder=""
                      id="gender"
                      options={catalogs.genders}
                      components={animatedComponents}
                      onChange={(e) => selectValue('gender', false, e)}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="temperament">Temperament</label><br />
                    <Select placeholder="" id="temperament" options={temperament} onChange={(e) => selectValue('temperament', false, e)} />
                  </div>

                  <div className="form-group">
                    <label htmlFor="color">Color</label><br />
                    <Select
                      placeholder=""
                      id="color"
                      options={catalogs.colors}
                      components={animatedComponents}
                      onChange={(e) => selectValue('color', false, e)}
                    />
                  </div>

                  <div className="divider" />

                  <div className="form-group">
                    <label htmlFor="height">Height</label><br />
                    <Select placeholder="" id="height" options={height} onChange={(e) => selectValue('height', false, e)} />
                  </div>


                  <div className="form-group">
                    <label htmlFor="postName">Name</label><br />
                    <input type="text" className="form-control" id="postName" name="postName" value={postName} onChange={e => onChangeText(e)} />
                  </div>

                  <div className="form-group">
                    <label htmlFor="postPrice">Price</label><br />
                    <input type="number" className="form-control" id="postPrice" name="postPrice" value={postPrice} onChange={e => onChangeText(e)} />
                  </div>

                  <div className="form-group checkboxRow">
                    <input
                      type="checkbox"
                      id="requireContract"
                      checked={requireContract}
                      name="requireContract" onChange={e => onChangeText(e)}
                    /><label className="checkBox" htmlFor="selling">Require Sale Contract</label>
                  </div>
                  <div className="form-group checkboxRow">
                    <input
                      type="checkbox"
                      id="isForRescueOrAdoption"
                      checked={isForRescueOrAdoption}
                      name="isForRescueOrAdoption" onChange={e => onChangeText(e)}
                    /><label className="checkBox" htmlFor="business">For Rescue/Adoption</label>
                  </div>

                </>
                :
                <PleaseWait message='Please wait...' />
            }
            <div className="addMorePostsInfo">
              <p>You can create more posts by clicking Add More Posts below. All posts will be submitted at once.</p>
              {errorMessage && <p className="errorMessage">{errorMessage}</p>}
            </div>

            {
              showStripeCard &&
              <div className="form-group stripeCardElementWrapper">
                <label htmlFor="stripeCard">Enter Payment Card</label><br /><br />
                <CardElement id="stripeCard" />
              </div>
            }

            <button onClick={() => {
              setShowPostForm(false);
              setShowUserForm(true);
            }} type="button" className="btn btn-primary formButton">Edit User</button>

            <button onClick={addMorePosts} type="button" className="btn btn-primary formButton addMore">Add More Posts</button>
            {/* {
              !showStripeCard &&
              <button onClick={() => setShowStripeCard(true)} type="button" className="btn btn-primary formButton">
                Add Payment
              </button>
            } */}

            <button onClick={createPost} type="button" className="btn btn-primary formButton">
              {/* <button onClick={subscribeUserInStripe} type="button" className="btn btn-primary formButton">  */}
              {
                isSubscribing ? 'Please wait, subscribing...' : 'Submit'
              }
            </button>


          </form>
          :
          isCompleted ?
            <PleaseWait onButtonClick={() => initiateReset()} message={`All set! You successfully created ${allPosts.length} ${allPosts.length > 1 ? 'posts' : 'post'} for ${email}.`} />
            :
            <PleaseWait message={`Creating ${allPosts.length} ${allPosts.length > 1 ? 'posts' : 'post'}, please wait...`} />
      }


    </div>
  )
}