const axios = require('axios');

export async function getAllAds() {
  try {
    const response = await axios.get(`/api/newsFeed/getAllAds`);
    return response;
  }
  catch (err) {
    console.error(err);
  }
}

export async function getAdsById(adId) {
  try {

    const response = await axios.post(`/api/newsFeed/getAdsById`, { adId });
    return response;
  }
  catch (err) {
    console.error(err);
  }
}

export async function getCatalogs() {
  try {
    const response = await axios.get(`/api/newsFeed/getCatalogs`);
    return response;
  }
  catch (err) {
    console.error(err);
  }
}

export async function getAdsByFilters(filters) {
  try {
    const response = await axios.post(`/api/newsFeed/getAdsByFilters`, { filters });
    return response;
  }
  catch (err) {
    console.error(err);
  }
}