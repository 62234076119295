const axios = require('axios');

export async function addEmail(email) {
    
    try {
        const response = await axios.post(`/api/mail/add-email/${email}`)
        // console.log(response)
        return response;
    }
    catch (err) {
        console.error(err);
    }
}