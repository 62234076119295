import moment from 'moment';
import React from 'react';
import { blogsEndPoint } from '../../config/config';

const BlogCard = ({ blog }) => {
  // Check if we have any blog data, if not just return null
  if (!blog && !blog.attributes) return null;
  // Get the blog id, title, subject and date, image and content
  const { title, subject, date, image } = blog?.attributes;
  const { name: blogSubject } = subject?.data?.attributes;
  // Retrieve image formats
  const { formats } = image.data?.attributes;
  // Get the large image data
  let { large: postImage } = formats;
  // if large is not available, Get the small format
  if (!postImage || postImage === undefined) {
    ({ small: postImage } = formats);
  }
  // if small is also not available, Get the small thumbnail format
  if (!postImage || postImage === undefined) {
    ({ thumbnail: postImage } = formats);
  }
  // Get the blog body...which should come in as an array for each paragraph since we are indenting the paragraphs
  const { body } = blog?.attributes;
  // function to take first 145 characters of the body
  const getFirst145Characters = (body) => {
    if (body.length > 145) {
      return body.substring(0, 145) + '...';
    }
    return body;
  };
  return (
    <a key={blog.id} aria-label={title} href={`/blog/${blog.id}/${blogSubject}`} className="cardWrapper">
      <img src={postImage?.url} alt="Card cap" className="cardImage" />
      <p className="blogPostTitle">{title}</p>
      <div className='blogDateAndSubject'>
        <span>{moment(date).format('MM/DD/YYYY')}</span>
        <span>{blogSubject}</span>
      </div>
      <div className='blogBody'>
        <p>{getFirst145Characters(body[0]?.text)}</p>
      </div>
      <div className='readMore'>
        <p>read more</p>
      </div>
    </a>
  );
};

export default BlogCard;
