import React, { useEffect, useState } from 'react';
import './NewsCard.css';
import moment from 'moment';
import useWindowDimensions from '../../../hooks/UseWindowDimensions';

import noUserImage from '../../../Images/no-image-user.jpeg';

import { getAllAds } from '../../../api/newsFeed';
import { Loading } from '../../Loading/Loading';
import Filter from '../../Filter/Filter';
// import Overlay from '../../Overlay/Overlay';

function NewsCard() {

	const [isLoadingData, setIsLoadingData] = useState(true);
	const [allAds, setAllAds] = useState([]);
	const [isFiltering, setIsFiltering] = useState(false);
	const [tempFeedPosts, setTempFeedPosts] = useState([]);
	const [showFilters, setShowFilters] = useState(false);
	const [appliedFilters, setAppliedFilters] = useState({
		type: 'selling',
		// clr: 'Color',
		clr: [],
		colorNames: [],
		br1: 'Breed',
		br2: [],
		breedNames: [],
		// age: 'Age',
		gender: [],
		genderNames: [],
		height: 'Height',
		minPrice: 0,
		maxPrice: 500,
		minAge: 0,
		maxAge: 30,
		minHeight: 1,
		maxHeight: 15,
		filterDistance: 30000
	});
	const { width } = useWindowDimensions();
	useEffect(() => {
		async function getData() {
			try {
				const response = await getAllAds();
				const ads = response?.data?.data?.list;
				console.log({response,ads})
				if (ads) {
					setAllAds(ads);
					setTempFeedPosts(ads);
					setIsLoadingData(false);
				} else {
					setAllAds([]);
					setTempFeedPosts([]);
					setIsLoadingData(false);
				}
			} catch (error) {
				setAllAds([]);
				setTempFeedPosts([]);
				setIsLoadingData(false);
			}
		}
		getData();
	}, []);

	// Cut text if it is more than 175 characters and add ...
	// const cutText = (text) => {
	// 	if (text.length > 175) {
	// 		return text.substring(0, 175) + '...';
	// 	}
	// 	return text;
	// };

	const toggleFilter = () => {
		if (allAds && allAds.length === 0) {
			console.log('No ads to filter');
			setAllAds(tempFeedPosts);
		}
		setShowFilters(!showFilters);
		setIsFiltering(false);
	}

	// Received data from the Filter component
	const setFeedPosts = (posts) => {
		setAllAds(posts);
		setShowFilters(false);
		setIsFiltering(true);
	};



	const renderSelectedFilters = (filterLabel, filterText) => {
		// console.log(filterText)
		if (filterLabel === 'Max Age' && filterText === 21) {
			filterText = '20+'
		}
		if (filterLabel === 'Max Height' && filterText === '19.1') {
			filterText = '19+'
		}
		if (filterLabel === 'Max Price' && filterText === '$51K') {
			filterText = '$50k+'
		}
		if (filterLabel === 'Distance' && filterText === '30000 miles') {
      filterText = '500+ miles'
    }

		return (
			<span className="filterText">
				<span className="filterLabel">{filterLabel}:</span> {filterText} | {" "}
			</span>
		)
	};

	const resetFilters = () => {
		setAllAds(tempFeedPosts);
		setShowFilters(false);
		setIsFiltering(false);
		setAppliedFilters({
			type: 'selling',
			clr: [],
			br2: [],
			gender: [],
			minPrice: 0,
			maxPrice: 500,
			minAge: 0,
			maxAge: 30,
			minHeight: 1,
			maxHeight: 15,
			filterDistance: 30000
		});
	};


	return (
		<div id='NewsFeedWrapper'>
			{

				// !posts.loading && 
				isFiltering && allAds && (
					<div className="noResults">
						<span className="noResultsHeader">{allAds.length === 0 ? "No results" : "Applied filters"}</span>
						{
							allAds.length === 0 &&
							<span className="noResultsText">
								There was no result found for the below (selected) filters. Please try again or reset your filters.
							</span>
						}

						<div className="filterResults">
							{appliedFilters.type !== undefined && renderSelectedFilters('Type', appliedFilters.type.toUpperCase())}
							{appliedFilters.filterDistance !== undefined && renderSelectedFilters('Distance', `${appliedFilters.filterDistance} miles`)}
							{appliedFilters.breedNames !== undefined && appliedFilters.breedNames.length > 0 && renderSelectedFilters('Breed', appliedFilters.breedNames.join(', '))}
							{appliedFilters.colorNames !== undefined && appliedFilters.colorNames.length > 0 && renderSelectedFilters('Color', appliedFilters.colorNames.join(', '))}
							{appliedFilters.genderNames !== undefined && appliedFilters.genderNames.length > 0 && renderSelectedFilters('Gender', appliedFilters.genderNames.join(', '))}

							{/* Don't show age values if default min/max is the same...it means nothing was changed */}
							{
								!(appliedFilters.minAge === 0 && appliedFilters.maxAge === 30) &&
								<>
									{appliedFilters.minAge !== undefined && renderSelectedFilters('Min Age', appliedFilters.minAge)}
									{appliedFilters.maxAge !== undefined && renderSelectedFilters('Max Age', appliedFilters.maxAge)}
								</>
							}

							{
								!(appliedFilters.minPrice === 0 && appliedFilters.maxPrice == 500) &&
								<>
									{appliedFilters.minPrice !== undefined && renderSelectedFilters('Min Price', `$${appliedFilters.minPrice}K`)}
									{appliedFilters.maxPrice !== undefined && renderSelectedFilters('Max Price', `$${appliedFilters.maxPrice}K`)}
								</>
							}
							{
								!(appliedFilters.minHeight === 1 && appliedFilters.maxHeight == 15) &&
								<>

									{appliedFilters.minHeight !== undefined && renderSelectedFilters('Min Height', `${appliedFilters.minHeight}`)}
									{appliedFilters.maxHeight !== undefined && renderSelectedFilters('Max Height', `${appliedFilters.maxHeight}`)}
								</>
							}

						</div>
						<button onClick={resetFilters} className="resetFilters resetResultFilters">
							Reset Filters
						</button>
					</div>
				)
			}
			<div id='CardFrame'>
				{
					(allAds && allAds.length > 0) &&
					<Filter
						toggleFilter={toggleFilter}
						showFilters={showFilters}
						setFeedPosts={setFeedPosts}
						tempFeedPosts={tempFeedPosts}
						setAppliedFilters={setAppliedFilters}
					/>
				}

				{/* {showFilters && <Overlay />} */}
				<div id="CardHolder">
					{
						isLoadingData &&
						<Loading centerOnPage />
					}
					{
						(allAds && allAds.length > 0) &&
						allAds.map((e, i) => {
							let ExtraPhotos = e.photos.length - 3
							return (
								<a href={`/post/${e.id}`} key={e.id} className={allAds.length < 2 ? 'CardContainerFull' : 'CardContainer'}>
									<div id='NewsCardTop'>
										<div id='NewsCardTopLeft'>
											<div id='ProfImgContainer'>
												{
													e.user.profile.photoUrl ?
														<img src={e.user.profile.photoUrl} alt='profile' id='ProfileImg' />
														:
														<img src={noUserImage} alt='profile' id='ProfileImg' />
												}
											</div>
											<div id='ProfileName'>{e.user.displayName}</div>
										</div>
										<div id='StatusBar' style={
											e.type === 'selling' ? { backgroundColor: "#07911C" }
												:
												e.type === 'leasing' ? { backgroundColor: "#C89933" } :
													{ backgroundColor: "#833063" }}>
											{e.type}
										</div>
									</div>
									{width < 550 ?
										<div id='DetailCol'>
											<div id='DetailRow'>
												<div className='Details'>{moment(e.createdAt).format('DD MMM')}</div>
												<div className='Details'>{e.breed.name}</div>
												<div className='Details'>{e.location}</div>
											{/* </div>
											<div id='DetailRow2'> */}
												<div className='Details'>{e.height}hh</div>
												<div className='Details'>Price: {
													new Intl.NumberFormat('en-US', {
														style: 'currency',
														currency: 'USD',
													}).format(e.price)
												}</div>
											</div>
										</div>
										:
										<div id='DetailRow'>
											<div className='Details'>{moment(e.createdAt).format('DD MMM')}</div>
											<div className='Details'>{e.breed.name}</div>
											<div className='Details'>{e.location}</div>
											<div className='Details'>{e.height}hh</div>
											<div className='Details'>Price: {
												new Intl.NumberFormat('en-US', {
													style: 'currency',
													currency: 'USD',
												}).format(e.price)
											}</div>
										</div>
									}
									<div id="NewsTitle"> {e.title}</div>
									{/* <div id='NewsDescript'>{cutText(e.description)}</div> */}
									<div id='NewsDescript'>{e.description}</div>
									<div id='NewsBottom'>
										<div id={e.photos.length > 1 ? "BottomLeftSm" : "BottomLeftLrg"}>
											<img className='NewsImg' src={e.photos[0]} alt='' />
										</div>
										{e.photos.length > 2 ?
											<div id="BottomMiddleHalves">
												<div className='MiddleHalf'>
													<img className='NewsImg' src={e.photos[1]} alt='' />
												</div>
												<div className='MiddleHalf'>
													<img className='NewsImg' src={e.photos[2]} alt='' />
												</div>
											</div>
											: e.photos.length > 1 ?
												<div id="BottomMiddleFull" >
													<img className='NewsImg' src={e.photos[1]} alt='' />
												</div>
												:
												<></>
										}
										{e.photos.length > 3 ?
											<div id="BottomRight">
												<div id='ExtraPicCol'>
													<p id="ExtraPicTxt">+{ExtraPhotos} Photos </p>
												</div>
												{/* <div id='LikeShareCont'>
											<div className='Row'>
												<img className='Icons' src={share} alt="" /><p className='LikeShareTxt'>Share with Friends!</p>
											</div>
											<div className='Row'>
												<img className='Icons' src={like} alt="" /><p className='LikeShareTxt'>{e.Likes}</p>
											</div>
										</div> */}
											</div>
											:
											<div id="BottomRightNoExtra">
												{/* <div id='LikeShareCont'>
											<div className='Row'>
												<img className='Icons' src={share} alt="" /><p className='LikeShareTxt'> Share with Friends!</p>
											</div>
											<div className='Row'>
												<img className='Icons' src={like} alt="" /><p className='LikeShareTxt'>{e.likes}</p>
											</div>
										</div> */}
											</div>
										}
									</div>
								</a>
							)
						})
					}
				</div>
			</div>
		</div>
	)
}

export default NewsCard
