import React, {useState, useEffect} from 'react'
import './EmailPopup.css'
import PopupClose from '../../../Images/popupclose.svg'
import useWindowDimensions from '../../../hooks/UseWindowDimensions'
import { addEmail } from '../../../api/mailchimp'



function EmailPopup() {
    
    const [popupOpen, setPopupOpen]= useState(true)
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState("")
    const [emailMsgControl, setEmailMsgControl] = useState(1)
    const { width } = useWindowDimensions();

    useEffect(() => {
        if (validateEmail(email)) {
            setEmailError("")
        }
    }, [email])

    useEffect(() => {
        // localStorage.setItem('Popup Open', true) 

        const popupStatus = localStorage.getItem('Popup Open')
        if (popupStatus !== 'false') {
            setPopupOpen(false)
        }
    }, [])

    async function sendEmail() {
        if (emailMsgControl === 1) {
            if (validateEmail(email)) {
                let response = await addEmail(email)
                if (response !== undefined) { 
                    setEmailMsgControl(2)
                    setEmail(" ")
                    setTimeout(function(){ 
                        popupViewed()
                    }, 1500);
                }
                else {
                    setEmailError("You have already subscribed with this email")
                }
            }
            else {
                setEmailError("Please provide a valid email")
            }
        }
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
 
    function popupViewed(clicked) {
        console.log(clicked)
        setPopupOpen(true)
        localStorage.setItem('Popup Open', false)
    }

    

    return (
        <div id={popupOpen === false ? 'EmailFrame' : 'HidePopup'} onClick={(clicked) => {popupViewed(clicked)}}>
            <div id='PopupContainer' onClick={(clicked) => {clicked.stopPropagation()}}>
                <div id='TopPopup'>
                    <div id="PopupTitle">Subscribe to Our Newsletter!</div>
                    <img src={PopupClose} id="PopupClose" className='noSelect' onClick={popupViewed} alt=''></img>
                </div>
                <div id='PopupDescription'>
                    Sign up to recieve updates and promotions!
                </div>
                <div id='PopupBottom'>
                    <input value={email} type='text' id='PopupInput' placeholder={width > 768 ? 'Please provide your email address' : 'Email Address'} onChange={(e) => { setEmail(e.target.value) }}></input>
                    <div id='PopupSignup' onClick={() => { sendEmail() }}>Sign Up</div>
                    <div id='PopupMobileClose' onClick={popupViewed} >No Thanks</div>                    
                </div>
                <p className={emailMsgControl === 1 ? "PopupEmailError" : "PopupEmailComplete"}>{emailMsgControl === 1 ? emailError : "Thank you for subscribing" }</p>
            </div>
        </div>
    )
}

export default EmailPopup
