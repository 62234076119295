import React from 'react';
import { Carousel } from '3d-react-carousal';
import { FullImageCarousel } from '../FullImageCarousel/FullImageCarousel';

export default function PhotoCarousel({ media }) {
  const [showFullImage, setShowFullImage] = React.useState(false);
  const [updatedMedia, setUpdatedMedia] = React.useState(media);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const onClose = () => {
    // re-order array so that the selected index is the first item
    updatedMedia.unshift(updatedMedia.splice(photoIndex, 1)[0]);
    setUpdatedMedia(updatedMedia);
    setShowFullImage(false);
  }
  const openToFullImage = (index) => {
    setPhotoIndex(index);
    setShowFullImage(true);
  }
  let slides = updatedMedia.map((item, index) => <img onClick={()=>openToFullImage(index)} key={item} src={item} alt={item} />);
  
  if (!media) return null;
  if(showFullImage) {
    return <FullImageCarousel 
    onCloseRequest={()=>onClose()} images={updatedMedia} 
    onMovePrevRequest={() => setPhotoIndex((photoIndex + updatedMedia.length - 1) % updatedMedia.length)}
    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % updatedMedia.length)}
    photoIndex={photoIndex}
  />
  }
  return (
    <Carousel slides={slides} autoplay={false} interval={1000} arrows={slides.length > 1} />
  );
}