
import React, { useEffect, useState } from 'react';
import './Filter.css';
import { RangeSlider, ButtonToolbar, ButtonGroup, Button } from 'rsuite';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { getAdsByFilters, getCatalogs } from '../../api/newsFeed';

const animatedComponents = makeAnimated();

const Filter = ({ showFilters, toggleFilter, setFeedPosts, tempFeedPosts, setAppliedFilters }) => {

  const [values, setValues] = useState({});
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(500);
  const [minAge, setMinAge] = useState(0);
  const [maxAge, setMaxAge] = useState(20);
  const [minHeight, setMinHeight] = useState(5);
  const [maxHeight, setMaxHeight] = useState(77);
  const [adType, setAdType] = useState('Selling');
  const [filterDistance, setFilterDistance] = useState(30000);
  const [currentLocationCoords, setCurrentLocationCoords] = useState({ latitude: 0, longitude: 0 });
  const [dataCatalog, setDataCatalog] = useState({});
  const [isGettingFilterData, setIsGettingFilterData] = useState(false);

  useEffect(() => {
    onChangeHeight([5, 77]);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setCurrentLocationCoords({ latitude: position.coords.latitude, longitude: position.coords.longitude });
      });
    } else { 
      alert("Geolocation is not supported by this browser.");
    }

    async function getData() {
      try {
        // Get the post data from the API using the post id we just got from the url
        const response = await getCatalogs();
        const catalogs = response?.data?.data;
        // Loop through object of arrays and add keys to each value
        let catalog = {};
        for (let key in catalogs) {
          catalog[key] = catalogs[key].map(item => {
            return {
              value: item.id,
              label: item.name,
              id: item.id,
              name: item.name
            }
          });
        }
        setDataCatalog(catalog);
      } catch (error) {
        setDataCatalog({});
      }
    }
    getData();
  }, []);

  const onChangePrice = (value) => {
    const [min, max] = value;
    setMinPrice(min);
    setMaxPrice(max);
  }
  const onChangeAge = (value) => {
    const [min, max] = value;
    setMinAge(min);
    setMaxAge(max);
  }
  const onChangeHeight = (value) => {
    const [minHeight, maxHeight] = value;
    let min = Math.floor((minHeight) / 4) + ((minHeight % 4) / 10);
    let max = Math.floor((maxHeight) / 4) + ((maxHeight % 4) / 10);

    setMinHeight(min);
    setMaxHeight(max);
  }
  const onChangeAdType = (value) => {
    setAdType(value);
  }

  const updateFilterDistance = option => {
    setFilterDistance(option);
  }

  const selectValue = (type, isMultiSelect, selectedItems) => {
    // console.log({ type, isMultiSelect, selectedItems });
    // Get name and id of selected item
    let name = '', id = '';
    if (isMultiSelect) {
      let selectedItemsArray = [];
      selectedItems.forEach(item => {
        selectedItemsArray.push(item.id);
      });
      name = selectedItemsArray;
      id = selectedItemsArray;
    } else {
      name = selectedItems.name;
      id = selectedItems.id;
    }

    let tempValues = { ...values };
    switch (type) {
      case 'breed1':
        tempValues.br1 = name;
        tempValues.br1_id = id;
        break;
      case 'breed2':
        tempValues.br2 = name;
        tempValues.br2_id = id;
        break;
      case 'height':
        tempValues.height = id;
        break;
      case 'gender':
        tempValues.gender = name;
        tempValues.gender_id = id;
        break;
      case 'color':
        tempValues.clr = name;
        tempValues.clr_id = id;
        break;
      default:

    }

    setValues({ ...tempValues });
    // return console.log({ tempValues });
  }

  /**
    * Baraka Mahili
    * @param {Number} lat The latitude of the location
    * @param {Number} lng The longitude of the location
    * @param {Number} radius The radius of the location
    * @returns Object containing minLat, maxLat, minLng, maxLng
  */
  const getCoordinatesBounds = (lat, lng, radius) => {
    const latRad = lat * (Math.PI / 180);
    const latRadEquator = latRad * 111320;
    const lngDistance = radius / (latRadEquator);
    const maxLat = lat + (radius / 1609.34 / 68.703);
    const minLat = lat - (radius / 1609.34 / 68.703);
    const maxLng = lng + lngDistance;
    const minLng = lng - lngDistance;
    const bounds = { maxLat, minLat, maxLng, minLng };
    return bounds;
  };

  const applyFilters = async () => {
    setIsGettingFilterData(true);
    let {
      clr,
      height,
      gender,
      br2
    } = values;
    // Set default values
    const filters = {
      type: adType.toLowerCase(),
      minPrice,
      maxPrice,
      minAge,
      maxAge,
      minHeight,
      maxHeight,
      filterDistance,
      coordinates: currentLocationCoords
    };

    if (clr && Array.isArray(clr) && clr.length) {
      filters['color'] = clr;
    }

    if (br2 && Array.isArray(br2) && br2.length) {
      filters['br2'] = br2;
    }

    if (gender && Array.isArray(gender) && gender.length) {
      filters['gender'] = gender;
    }

    if (height && height !== '' && height !== 'Height') {
      filters['height'] = Number(height);
    }

    setAppliedFilters({
      type: filters.type,
      clr: filters.color,
      br2: filters.br2,
      gender: filters.gender,
      minPrice: filters.minPrice,
      maxPrice: filters.maxPrice,
      minAge: filters.minAge,
      maxAge: filters.maxAge,
      minHeight: filters.minHeight,
      maxHeight: filters.maxHeight,
      filterDistance: filters.filterDistance,
      coordinates: filters.coordinates,
    });

    let filterFeeds = [];
    try {
      filterFeeds = await getAdsByFilters(filters);
      if (!filterFeeds?.data?.data?.list) {
        setIsGettingFilterData(false);
        console.log('Error getting filter data', filterFeeds);
        setFeedPosts([]);
        setTimeout(() => {
          alert("There was an error applying your filters. Please try again.");
        }, 1000);
        return false;
      }
    } catch (error) {
      setIsGettingFilterData(false);
      console.log('Error getting filter data', error);
      setFeedPosts([]);
      setTimeout(() => {
        alert("There was an error applying your filters. Please try again.");
      }, 1000);
    }

    // let { list } = posts.feed; // Data for what's already received in the front end
    let { list } = filterFeeds?.data?.data; // Data from the backend

    // Start by filtering by price, age using min and max price, age respectively
    // Also, add breed that was selected if there
    let newList = [];

    // First, get bounds for distance filters to use in the if condition below
    // Find available horses within the distance
    const { latitude, longitude } = filters?.coordinates;
    
    // Check distance, should only get horses within x miles:
    const filterDistanceInMiles = filters.filterDistance;
    // const filterDistanceInMiles = 30;
    const radius = filterDistanceInMiles * 1609.344; // convert miles to meters... 1 mile = 1609.344 meters
    const bounds = getCoordinatesBounds(latitude, longitude, radius);
    const minLat = bounds.minLat;
    const maxLat = bounds.maxLat;
    const minLng = bounds.minLng;
    const maxLng = bounds.maxLng;
    // console.log({ filters, list, minLat, maxLat, minLng, maxLng });
    // End of get bounds for distance filters

    list.forEach(item => {
      if (
        // ((item.price >= filters.minPrice * 1000) && (item.price <= filters.maxPrice * 1000)) && // price between minPrice and maxPrice
        (((item.price >= filters.minPrice * 1000) && (item.price <= filters.maxPrice * 1000)) || ((item.price >= filters.minPrice * 1000) && (filters.maxPrice === 51) && (item.price >= filters.maxPrice * 1000))) && // price between minPrice and maxPrice
        // ((item.age >= filters.minAge) && (item.age <= filters.maxAge)) && // age between minAge and maxAge
        (((item.age >= filters.minAge) && (item.age <= filters.maxAge)) || ((item.age >= filters.minAge) && (filters.maxAge === 21) && (item.age >= filters.maxAge))) && // price between minPrice and maxPrice
        // ((item.height >= filters.minHeight) && (item.age <= filters.maxHeight)) && // height between minHeight and maxHeight
        (((item.height >= filters.minHeight) && (item.height <= filters.maxHeight)) || ((item.height >= filters.minHeight) && (filters.maxHeight === 19.1) && (item.height >= filters.maxHeight))) && // price between minPrice and maxPrice

        // ------------- FILTER DISTANCE -------------
        // Add filter by coordinates
        ((item.coordinates.lng >= minLng) && (item.coordinates.lng <= maxLng)) && // longitude between minLng and maxLng
        ((item.coordinates.lat >= minLat) && (item.coordinates.lat <= maxLat)) && // longitude between minLat and maxLat

        item.type === filters.type // type
      ) {
        // If those conditions are met, push to new list
        newList.push(item);
      }
    });

    const listWithBreedFilters = [];
    const breedNames = [];
    if (filters.br2 && Array.isArray(filters.br2) && filters.br2.length > 0) {
      newList.forEach(item => {
        if (filters.br2.indexOf(item.breed.id) > -1) {
          listWithBreedFilters.push(item);
        }
      });
      newList = listWithBreedFilters;
      // console.log({dataCatalog});
      dataCatalog.breeds.forEach(e => {
        if (filters.br2.indexOf(e.id) > -1) {
          breedNames.push(e.name);
        }
      })

      // Get list of breeds
    }
    filters['breedNames'] = [...new Set(breedNames)];// Update array of selected breeds (Set only returns unique values)
    setAppliedFilters({ ...filters });


    const listWithColorFilters = [];
    const colorNames = [];
    if (filters.color && Array.isArray(filters.color) && filters.color.length > 0) {
      newList.forEach(item => {
        if (filters.color.indexOf(item.color.id) > -1) {
          listWithColorFilters.push(item);
          // colorNames.push(item.color.name);
        }
      });
      newList = listWithColorFilters;
      dataCatalog.colors.forEach(e => {
        if (filters.color.indexOf(e.id) > -1) {
          colorNames.push(e.name);
        }
      })
    }
    filters['colorNames'] = [...new Set(colorNames)];// Update array of selected breeds (Set only returns unique values)
    setAppliedFilters({ ...filters });

    // Filter Genders
    const listWithGenderFilters = [];
    const genderNames = [];
    if (filters.gender && Array.isArray(filters.gender) && filters.gender.length > 0) {
      newList.forEach(item => {
        if (filters.gender.indexOf(item.gender.id) > -1) {
          listWithGenderFilters.push(item);
        }
      });
      newList = listWithGenderFilters;
      dataCatalog.genders.forEach(e => {
        if (filters.gender.indexOf(e.id) > -1) {
          genderNames.push(e.name);
        }
      })
    }
    filters['genderNames'] = [...new Set(genderNames)];
    setAppliedFilters({ ...filters });
    
    const finalList = newList;

    setFeedPosts(finalList);
    setIsGettingFilterData(false);
  }

  const resetFilters = () => {
    // Show original data
    setAppliedFilters({
      type: 'selling',
      clr: [],
      br2: [],
      gender: [],
      minPrice: 0,
      maxPrice: 500,
      minAge: 0,
      maxAge: 30,
      minHeight: 1,
      maxHeight: 15,
      filterDistance: 30000
    });
    updateFilterDistance(30000);
    return setFeedPosts(tempFeedPosts);
  }

  return (
    <div className={showFilters ? "filterWrapperVisible" : "filterWrapper"}>
      {/* <button onClick={toggleFilter} id="filterToggle">
        <i className="fa fa-filter fa-1x" aria-hidden="true"></i> Filter
      </button>
      {
        showFilters && */}
      <div id="filters">
        <div className='filterSection'>
          <span className='filterSectionLabel'>Type</span>
          <ButtonToolbar>
            <ButtonGroup size="sm" justified>
              <Button appearance={adType === "Selling" ? "primary" : "default"} onClick={() => onChangeAdType('Selling')}>Selling</Button>
              <Button appearance={adType === "Searching" ? "primary" : "default"} onClick={() => onChangeAdType('Searching')}>Searching</Button>
              <Button appearance={adType === "Leasing" ? "primary" : "default"} onClick={() => onChangeAdType('Leasing')}>Leasing</Button>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
        <div className='filterSection'>
          <span className='filterSectionLabel'>Distance (in miles)</span>
          <ButtonToolbar>
            <ButtonGroup size="sm" justified>
              <Button appearance={filterDistance === 50 ? "primary" : "default"} onClick={() => setFilterDistance(50)}>50</Button>
              <Button appearance={filterDistance === 100 ? "primary" : "default"} onClick={() => setFilterDistance(100)}>100</Button>
              <Button appearance={filterDistance === 250 ? "primary" : "default"} onClick={() => setFilterDistance(250)}>250</Button>
              <Button appearance={filterDistance === 500 ? "primary" : "default"} onClick={() => setFilterDistance(500)}>500</Button>
              <Button appearance={filterDistance === 30000 ? "primary" : "default"} onClick={() => setFilterDistance(30000)}>500+</Button>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
        <div className='filterSection'>
          <span className='filterSectionLabel'>Price</span>
          <RangeSlider
            defaultValue={[0, 500]}
            min={0}
            max={500}
            onChange={onChangePrice}
            tooltip={false}
          />
          <div className='sliderMarkers'>
            <span><span>Min:</span> ${minPrice}K</span>
            <span><span>Max:</span> ${maxPrice}K</span>
          </div>
        </div>

        <div className='filterSection'>
          <span className='filterSectionLabel'>Age</span>
          <RangeSlider
            defaultValue={[0, 20]}
            min={0}
            max={20}
            onChange={onChangeAge}
            tooltip={false}
          />
          <div className='sliderMarkers'>
            <span><span>Min:</span> {minAge}</span>
            <span><span>Max:</span> {maxAge > 19 ? `${maxAge}+` : maxAge}</span>
          </div>
        </div>

        <div className='filterSection'>
          <span className='filterSectionLabel'>Height</span>
          <RangeSlider
            defaultValue={[5, 77]}
            min={5}
            max={77}
            step={1}
            onChange={onChangeHeight}
            tooltip={false}
          />
          <div className='sliderMarkers'>
            <span><span>Min:</span> {minHeight}H</span>
            <span className="maxHeight"><span>Max:</span> {maxHeight > 19 ? `${maxHeight}H+` : `${maxHeight}H`}</span>
          </div>
        </div>

        <div className='filterSection'>
          <span className='filterSectionLabel'>Breed</span>
          <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            isMulti
            options={dataCatalog.breeds}
            onChange={(e) => selectValue('breed2', true, e)}
          />
        </div>

        <div className='filterSection'>
          <span className='filterSectionLabel'>Color</span>
          <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            isMulti
            options={dataCatalog.colors}
            onChange={(e) => selectValue('color', true, e)}
          />
        </div>

        <div className='filterSection'>
          <span className='filterSectionLabel'>Gender</span>
          <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            isMulti
            options={dataCatalog.genders}
            onChange={(e) => selectValue('gender', true, e)}
          />
        </div>

        <div id="filterButtonsWrapper">
          <button onClick={resetFilters} className="resetFilters">
            Reset
          </button>
          <button onClick={applyFilters} id="applyFilters">
            {isGettingFilterData ? "Please wait..." : "APPLY"}
          </button>

        </div>

      </div>
      {/* } */}
    </div>
  )
}

export default Filter;