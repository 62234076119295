import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NewsHead from '../components/newsfeed/NewsHead/NewsHead';
import PostCard from '../components/PostCard/PostCard';
import { getAdsById } from '../api/newsFeed';
import SubscribeRibbon from '../components/home/SubscribeRibbon/SubscribeRibbon';
import Footer from '../components/home/Footer/Footer';
import { Loading } from '../components/Loading/Loading';



function SinglePost() {
	const [post, setPost] = useState({});
	// Page will load with a url like /post/12345 (where 12345 is the post id), so we can use the useParams hook to get the id
	let { id } = useParams();

	useEffect(() => {
		async function getData() {
			try {
				// Get the post data from the API using the post id we just got from the url
				const response = await getAdsById(id);
				const ads = response?.data?.data?.list;
				if (ads) {
					setPost(ads); // Set the post data to the state
				} else {
					setPost({});
				}
			} catch (error) {
				setPost({});
			}
		}
		getData();
	}, [id])

	return (
		<main>
			<NewsHead />
			<section id="postCardWrapper">
				{
					(post && Object.keys(post).length > 0) ?
						<PostCard post={post} />
						:
						<Loading />
				}
			</section>
			<SubscribeRibbon />
			<Footer />
		</main>
	)
}

export default SinglePost;