import React from 'react';
import './Footer.css';
import eqlogo from '../../../Images/eqlogo.png';


function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <div id='FooterFrame'>
            <div id='FooterBody'>
                <div id='FooterCopyright'>
                    Copyright &copy; {currentYear} Equinoble, Inc. - All Rights Reserved.
                </div>
                <div id='FooterTitleContainer'>
                    <img className='noSelect' id="FootEQIcon" src={eqlogo} alt='equinoble logo'></img>
                    <div id='FootEQTitle'>EQUINOBLE</div>
                </div>
                <div id="TermsContainer">
                    <a className='TermLinks' href='/terms-of-service'>Terms of Service</a>
                    <a className='TermLinks' href='/privacy-policy'>Privacy Policy</a>
                </div>
            </div>
        </div>
    )
}

export default Footer
