import React from 'react';
import './LandingSection.css';
import eqlogo from '../../../Images/eqlogo.png';
import landingnewsfeed from '../../../Images/landingnewsfeed.png';

function LandingSection() {

    return (
        <div id="LandingContainer">
            <div id="BorderContainer">
                <div id="LandContentContainer">
                    <div>
                        <div id="LeftContentContainer">
                            <div id="TopLeftLandContainer">
                                <div id="LandLogoContainer">
                                    <img className='noSelect' id="LandEQIcon" src={eqlogo} alt='equinoble logo'></img>
                                </div>
                                <div className="BrandName">EQUINOBLE</div>
                                <div id='HomeLinkContainer'>
                                    <a id='HomeLink' className='NavText' href='/'>Home</a>
                                    <a id='NewsLink' className='NavText' href='/newsfeed'>Newsfeed</a>
                                    <a id='NewsLink' className='NavText' href='/blog'>Blog</a>
                                </div>
                            </div>
                            <div id="MidLeftLandContainer">
                                <div id="LandingDescription">
                                    Equine classifieds in the palm of your hand.<br />
                                    Expertly designed.<br />
                                    Easy to use.
                                </div>
                            </div>
                        </div>
                        <div id="RightContentContainer">
                            <img className='noSelect' id="LandingScreen" src={landingnewsfeed} alt='equinoble newsfeed'></img>
                        </div>
                        <div id="LowerContentContainer">
                            <a href="#SubscribeFrame" id="SubscribeBtn">
                                <p className="btnText noSelect">Subscribe for updates!</p>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default LandingSection
