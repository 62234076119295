import React from 'react';
import useWindowDimensions from '../../../hooks/UseWindowDimensions'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

import './ScreenCarousel.css';

import createlistcarousel from '../../../Images/createlistcarousel.png';
import profilecarousel from '../../../Images/profilecarousel.png';
import chatscarousel from '../../../Images/chatscarousel.png';

import SwiperCore, {
    Pagination,
    Autoplay
} from 'swiper/core';

SwiperCore.use([Pagination, Autoplay]);

function ScreenCarousel(props) {

    const { width } = useWindowDimensions();

    const screens = [
        createlistcarousel,
        profilecarousel,
        chatscarousel
    ] 

    return (
        <div id='CarouselContainer'> 
            <div className='SwiperContainer noSelect'>
                <Swiper
                            slidesPerView={ width > 625 ? 3 : 1}
                            spaceBetween={40}
                            slidesPerGroup={1}
                            loop={true}
                            slideToClickedSlide={true}
                            loopFillGroupWithBlank={false}
                            className="mySwiper"
                            centeredSlides={true}
                            autoplay={
                                {
                                delay: 5000
                                }
                            }
                            pagination={{
                                type:'bullets',
                                clickable:true
                            }}
                        >
                            {screens.map((e, i) => {
                                return (
                                    <SwiperSlide key={i}><img src={e} alt='carousel slides' /></SwiperSlide>
                                )
                            })}
                        </Swiper>
            </div>
        </div>
    )
}

export default ScreenCarousel

