import React, { useState, useEffect } from 'react';
import './SubscribeRibbon.css';
import useWindowDimensions from '../../../hooks/UseWindowDimensions'
import { addEmail } from '../../../api/mailchimp'

function SubscribeRibbon() {
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState("")
    const [emailMsgControl, setEmailMsgControl] = useState(1)
    const { width } = useWindowDimensions();

    useEffect(() => {
        if (validateEmail(email)) {
            setEmailError("")
        }
    }, [email])

    async function sendEmail() {
        if (emailMsgControl === 1) {
            if (validateEmail(email)) {
                let response = await addEmail(email)
                if (response !== undefined) {
                    setEmailMsgControl(2)
                    setEmail(" ")
                }
                else {
                    setEmailError("You have already subscribed with this email")
                }
            }
            else {
                setEmailError("Please provide a valid email")
            }
        }
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    return (
        <div id='SubscribeFrame'>
            <div id='SubscribeRibbonBody'>
                <div id='SubscribeRibbonHead'>
                    SUBSCRIBE & STAY TUNED
                </div>
                <div id='SubsribeRibbonSubhead'>
                    Sign up to recieve updates and promotions!
                </div>
                <div id='SubsribeRibbonEmailContainer'>
                    <input value={email} id='SubsribeEmailTxt' type='text' placeholder={width > 768 ? 'Please provide your email address' : 'Email Address'} onChange={(e) => { setEmail(e.target.value) }}></input>
                    <div id='SubscribeEmailBtn' onClick={() => { sendEmail() }}>
                        Sign up
                    </div>
                </div>
                <p className={emailMsgControl === 1 ? "SubscribeEmailError" : "SubscribeEmailComplete"}>{emailMsgControl === 1 ? emailError : "Thank you for subscribing"}</p>
            </div>
        </div>
    )
}

export default SubscribeRibbon


