import React from 'react';
import QRCodeReact from 'qrcode.react';
import './QRCode.css';
import {isMobile} from 'react-device-detect';
import { ANDROID_PACKAGE_NAME, firebaseDynamicLinkPrefix, IOS_APP_STORE_ID, IOS_BUNDLE_IDENTIFIER } from '../../config/config';
const QRCode = ({ qrCodeText, qrCodeSize, label, fgColor, bgColor }) => {
  // "https://equinoble.page.link/?link=http://localhost:3000/post/6NZ1swnqbz9nzhj6M0I6&apn=com.equinoble_app&ibi=com.equinoble&isi=1570926714"
  const deepLink = `${firebaseDynamicLinkPrefix}/?link=${qrCodeText}&apn=${ANDROID_PACKAGE_NAME}&ibi=${IOS_BUNDLE_IDENTIFIER}&isi=${IOS_APP_STORE_ID}`;
  // console.log({deepLink})
  return (
    <button 
      id="qrCodeContainer" 
      aria-label='Scan this QR code to open the Equinoble mobile app'
      role="link"
      onClick={isMobile ? () => window.open(deepLink, '_blank') : null}
    >
      <QRCodeReact 
        fgColor={fgColor || "#855B80"} 
        bgColor={bgColor || "white"} 
        // size={qrCodeSize || 40} 
        value={deepLink} 
      />
      <span>{label || 'View in App'}</span>
    </button>
  )
}

export default QRCode;