// Create context for app
import React from 'react';
const context = {
  user: {},
  setUser: (userData) => { },
  catalogs: {},
  setCatalogs: (catalogData) => { },
  values: {},
  setValues: (valueData) => { },
}
export const AppContext = React.createContext(context);