import React, { useState, useContext } from 'react';
import PostForm from '../components/PostForm/PostForm';
import { AppContext } from '../context/AppContext';
import eqlogo from "../Images/eqlogo.png";
import { getUserIdByEmail } from "../api/webForm";
import "./WebForm.css";
export default function WebForm() {

  const [userId, setUserId] = useState('');
  const { user, setUser, setCatalogs } = useContext(AppContext);
  const [showUserForm, setShowUserForm] = useState(true);
  const [showPostForm, setShowPostForm] = useState(false);


  // const [userData, setUserData] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [type, setType] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [userType, setUserType] = useState("existingUser");
  const [isForExistingUserForm, setIsForExistingUserForm] = useState(true);


  const [creatingUser, setCreatingUser] = useState(false);
  const [gettingUserData, setGettingUserData] = useState(false);

  const onChangeText = (e) => {
    if (e.target.name === "firstName") {
      setFirstName(e.target.value);
    } else if (e.target.name === "lastName") {
      setLastName(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    } else if (e.target.name === "userName") {
      setUserName(e.target.value);
    } else if (e.target.name === "type") {
      setType(e.target.defaultValue);
    } else if (e.target.name === "businessName") {
      setBusinessName(e.target.value);
    } else if (e.target.name === "userType") {
      setUserType(e.target.value);
      setIsForExistingUserForm(e.target.value === "existingUser");
    }
  }


  const getUserInfo = async (e) => {
    e.preventDefault();
    // Check if all fields are filled
    if (email === "") {
      alert("Please enter the email of an existing user");
      return;
    }
    // Check if email is valid
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert("Please enter a valid email address");
      return;
    }

    setGettingUserData(true);
    const data = {
      email,
      isForExistingUser: true
    };

    try {
      const json = await getUserIdByEmail(data);
      if (json.status === 200 && json.data.response.data.userData !== null) {
        setUser(json.data.response.data.userData); // set to global context
        setCatalogs(json.data.response.data.catalogs); // set to global context
        setUserId(json.data.response.data.userData.id || json.data.response.data.userData.uid);
        setGettingUserData(false);
      } else {
        alert(json.data.response.message);
        setGettingUserData(false);
      }
    } catch (error) {
      console.log({ error });
      alert("Error getting user data");
      setGettingUserData(false);
    }

  }

  const goToNextPage = async (e) => {
    setShowPostForm(true);
    setShowUserForm(false);
    setCreatingUser(false);
    setGettingUserData(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if all fields are filled
    if (firstName === "" || lastName === "" || email === "" || password === "" || userName === "" || type === "") {
      alert("Please fill all fields");
      return;
    }
    // Check if email is valid
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert("Please enter a valid email");
      return;
    }
    // Check if type is selected and bussines name is filled if type is bussines
    if (type === "bussines" && businessName === "") {
      alert("Please enter a business name");
      return;
    }

    // Check if password is at least 8 characters long
    if (password.length < 6) {
      alert("Password must be at least 6 characters long");
      return;
    }

    setCreatingUser(true);
    const data = {
      displayName: userName,
      email,
      firstname: firstName,
      lastname: lastName,
      password,
      type,
      username: userName
    };
    if (type === "bussines") {
      data.businessname = businessName;
    }
    try {
      const json = await getUserIdByEmail(data);
      if (json.status === 200) {
        setUser(json.data.response.data.userData); // set to global context
        setCatalogs(json.data.response.data.catalogs); // set to global context
        setUserId(json.data.response.data.userData.id || json.data.response.data.userData.uid);
        setShowPostForm(true);
        setShowUserForm(false);
        setCreatingUser(false);
      } else {
        alert("Error creating user");
        setCreatingUser(false);
      }
    } catch (error) {
      console.log({ error });
      alert("Error creating user");
      setCreatingUser(false);
    }

  }

  const resetUserForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setUserName("");
    setType("");
    setBusinessName("");
    setShowPostForm(false);
    setShowUserForm(true);
    setIsForExistingUserForm(true);
    setUserType("existingUser");
    setUser(null);
    setUserId(null);
  }



  return (
    <div id="webFormWrapper">

      <div className="container">
        <div className="inner">
          <div id="TopLeftLandContainer">
            <div id="LandLogoContainer">
              <img className='logo' id="LandEQIcon" src={eqlogo} alt='equinoble logo'></img>
            </div>
            <div className="BrandName">EQUINOBLE</div>
          </div>

          {

            showUserForm &&
            <>
              <p className="userTypeInfo">Is this post for a New or Existing user?</p>
              <div className="form-group">
                <br />
                <input
                  type="radio"
                  className="form-control"
                  id="existingUser"
                  value="existingUser"
                  checked={userType === 'existingUser'}
                  name="userType" onChange={e => onChangeText(e)}
                /><label htmlFor="existingUser">Existing User</label><br />
                <input
                  type="radio"
                  className="form-control"
                  id="newsUser"
                  value="newsUser"
                  checked={userType === 'newsUser'}
                  name="userType" onChange={e => onChangeText(e)}
                /><label htmlFor="newsUser">New User</label><br />
              </div>
              <div className="divider" />

              {
                isForExistingUserForm ?
                  <>
                    <p className="existingUserInfo">Please provide the email for the existing user below:</p>
                    <form id="webForm">
                      <div className="form-group">
                        <label htmlFor="email">Email</label><br />
                        <input type="email" className="form-control" id="email" name="email" value={email} onChange={e => onChangeText(e)} />
                      </div>
                      {
                        user && userId &&
                        <div className="userDetails">
                          <p className="userDetailsHeader">We found a user with the following information:</p>
                          <p className="userDetailsItem">Display Name: <span>{user.displayName}</span></p>
                          <p className="userDetailsItem">Email address: <span>{email}</span></p>
                          <p className="nextInfo">Click Next to create posts for this user</p>
                        </div>
                      }

                      <div className='buttonsWrapper'>
                        <button onClick={getUserInfo} type="button" className="btn btn-primary formButton">
                          {gettingUserData ? "Please wait..." : "Get User Data"}
                        </button>
                        {
                          user && userId &&
                          <button onClick={goToNextPage} type="button" className="btn btn-primary formButton">Next</button>
                        }

                      </div>

                    </form>
                  </>
                  :
                  <>
                    <div id="rectangleElement" />
                    <p id="sectionTitle">Create user</p>
                    <form id="webForm">
                      <div className="form-group">
                        <label htmlFor="firstName">First Name</label><br />
                        <input type="text" className="form-control" id="firstName" name="firstName" value={firstName} onChange={e => onChangeText(e)} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="lastName">Last Name</label><br />
                        <input type="text" className="form-control" id="lastName" name="lastName" value={lastName} onChange={e => onChangeText(e)} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label><br />
                        <input type="email" className="form-control" id="email" name="email" value={email} onChange={e => onChangeText(e)} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="userName">Username</label><br />
                        <input type="text" className="form-control" id="userName" name="userName" value={userName} onChange={e => onChangeText(e)} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Password</label><br />
                        <input type="password" className="form-control" id="password" name="password" value={password} onChange={e => onChangeText(e)} />
                      </div>
                      <div className="form-group">
                        <p className="userTypeHeader">Account Type</p>
                        <input type="radio" className="form-control" id="individual" checked={type === 'individual'} value="individual" name="type" onChange={e => onChangeText(e)} />
                        <label htmlFor="individual">Individual</label><br />
                        <input type="radio" className="form-control" id="bussines" checked={type === 'bussines'} value="bussines" name="type" onChange={e => onChangeText(e)} />
                        <label htmlFor="bussines">Business</label>
                      </div>
                      {
                        type === 'bussines' ?
                          <div className="form-group">
                            <label htmlFor="businessName">Business Name</label><br />
                            <input type="text" className="form-control" id="businessName" name="businessName" value={businessName} onChange={e => onChangeText(e)} />
                          </div>
                          : null
                      }
                      <button onClick={handleSubmit} type="button" className="btn btn-primary formButton">
                        {creatingUser ? "Please wait..." : "Next"}
                      </button>
                    </form>
                  </>
              }
            </>
          }

          {
            showPostForm && userId &&
            <PostForm
              userId={userId}
              setShowPostForm={setShowPostForm}
              setShowUserForm={setShowUserForm}
              resetUserForm={resetUserForm}
              email={email}
              userData={{ firstName, lastName, email, userId }}
            />
          }
        </div>
      </div>

    </div>
  )
}