import React from 'react'
import './OurMission.css'
import youlist from '../../../Images/youlist.png'
import wegive from'../../../Images/wedonate.png'
import theysurvive from '../../../Images/theysurvive.png'

function OurMission(props) {
    return (
        <div id='MissionContainer'>
            <div id='MissionTop'>
                <div id='MissionHead'></div>
                <p id="OurMissionText">Our Mission</p>
            </div>
            <div className='MissionContent'>
                <div className='MissionSubHead'>
                    <p className='MissionSubHeadText'>You List</p>
                    <img className='noSelect' id='MissionSubHeadList' src={youlist} alt='you list icon'></img>
                </div>
                <p className='MissionBodyText'>
                Easily place your classified listing on <br/>
                our industry leading mobile app.
                </p>
            </div>
            <div className='MissionContent'>
                <div className='MissionSubHead'>
                    <p className='MissionSubHeadText'>We Give</p>
                    <img className='noSelect' id='MissionSubHeadGive' src={wegive} alt='we give icon'></img>
                </div>
                <p className='MissionBodyText'>
                We will donate a portion of every listing <br/>
                to approved equine non-profits<br/>
                nationwide.
                </p>
            </div>
            <div className='MissionContent'>
                <div className='MissionSubHead'>
                    <p className='MissionSubHeadText'>They Survive</p>
                    <img className='noSelect' id='MissionSubHeadHeart' src={theysurvive} alt='they survive icon'></img>
                </div>
                <p className='MissionBodyText'>
                Your listing will help provide a fighting <br/>
                chance to horses in need!
                </p>
            </div>
        </div>
    )
}

export default OurMission

