import React, { useState, useEffect } from 'react'
import hamburger from '../../Images/hamburger.png'
import '../navbar/Navbar.css';

function Hamburger() {
    const [hamburgerOpen, setHamburgerOpen] = useState(false)

    // const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);

    const onScroll = (e) => {
        setScrollTop(e.target.documentElement.scrollTop);
        // setScrolling(e.target.documentElement.scrollTop > scrollTop);
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        // console.log(scrollTop);
        if (scrollTop > 5) {
            if (hamburgerOpen === true) {
                setHamburgerOpen(!hamburgerOpen)
            }
        }
    }, [scrollTop])

    return (
        <>
          <img id='NavHamburger' src={hamburger} onClick={() => { setHamburgerOpen(!hamburgerOpen) }} alt='' />
                        <div className={hamburgerOpen === false ? "hamburgerStyling" : ("hamburgerStyling", "OpenNav")}>
                            {/* {hamburgerOpen === true ? */}
                                <div id='MobileNavLinkContainer'>
                                    <a className='MobileLink' href='/'> Home </a>
                                    <div className='NavBorder'></div>
                                    <a className='MobileLink' href='/newsfeed'> Newsfeed </a>
                                    <a className='MobileLink' href='/blog'> Blog </a>
                                </div>
                                {/* :
                                <></>
                            } */}
                        </div>  
        </>
    )
}

export default Hamburger
