import React from 'react';
import './Navbar.css';
import logo from '../../Images/eqlogo.png';
import useWindowDimensions from '../../hooks/UseWindowDimensions';
import Hamburger from '../shared/Hamburger';

// var currentScrollPos


function Navbar() {
    const { width } = useWindowDimensions();
    

    // window.onscroll = function () {
    //     currentScrollPos = window.pageYOffset;
    //     console.log(currentScrollPos)
    //     if (currentScrollPos > 1 && currentScrollPos < 200) {
    //         setHamburgerOpen(false)
    //     } else {
    //         //   document.getElementsByClassName("OpenNav").style.display = "none";
    //     }
    // }



    return (
        <div id='NavFrame'>
            <div id='NavContainer'>
                <div id='NavBrandContainer'>
                    <div id='NavLogoContainer'>
                        <img id='NavLogo' src={logo} alt='EQ logo'></img>
                    </div>
                    <p id='NavTitle'>Equinoble</p>
                </div>
                {width < 420 ?
                    <div id='LinkContainer'>
                        <Hamburger />
                    </div>
                    :
                    <div id='LinkContainer'>
                        <a id='HomeLink' className='NavText' href='/'>Home</a>
                        <a id='NewsLink' className='NavText' href='/newsfeed'>Newsfeed</a>
                        <a id='NewsLink' className='NavText' href='/blog'>Blog</a>
                    </div>
                }
            </div>
        </div>
    )
}

export default Navbar
