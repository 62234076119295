import React from 'react';
import '../index.css'
import EmailPopup from "../components/home/EmailPopup/EmailPopup"
import LandingSection from "../components/home/LandingSection/LandingSection"
import TopRibbon from "../components/home/TopRibbon/TopRibbon"
import OurMission from "../components/home/OurMission/OurMission"
import ScreenCarousel from "../components/home/ScreenCarousel/ScreenCarousel"
import OurApp from '../components/home/OurApp/OurApp';
import SubscribeRibbon from '../components/home/SubscribeRibbon/SubscribeRibbon';
import Footer from '../components/home/Footer/Footer';

function Home() {
    return (
        <div>
            <EmailPopup />
            <LandingSection />        
            <TopRibbon />
            <div id='MissionCarouselContainer'>
                <div id='MissionCarouselFrame'>
                    <OurMission />
                    <ScreenCarousel />
                </div>
            </div>
            <OurApp />
            <SubscribeRibbon />
            <Footer />
        </div>
    )
}

export default Home
