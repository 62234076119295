import React from 'react';
import './PageTitle.css';
const PageTitle = ({title, isScaledDown}) => {
  return (
    <div className={`pageTitleContainer ${isScaledDown ? 'scaleDown' : ''}`}>
      <div className={`pageTitleBar ${isScaledDown ? 'scaleDown' : ''}`} />
      <h1 className={`pageTitle ${isScaledDown ? 'scaleDown' : ''}`}>{title}</h1>
    </div>
  );
};

export default PageTitle;
