import React, { useState, useMemo } from 'react';
import '../newsfeed/NewsCard/NewsCard.css';
import './PostCard.css';
import moment from 'moment';
import PageTitle from '../../components/PageTitle/PageTitle';
import useWindowDimensions from '../../hooks/UseWindowDimensions';

import noUserImage from '../../Images/no-image-user.jpeg';
import share from '../../Images/share.png';
import like from '../../Images/like.png';
import PhotoCarousel from '../PhotoCarousel/PhotoCarousel';
import QRCode from '../QRCode/QRCode';

function PostCard({ post: e, children }) {
  const [showSocialSection] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const { width } = useWindowDimensions();

  const toggleMoreDetails = () => {
    setShowMoreDetails(!showMoreDetails);
  }

  // Memoise carousel so it only renders when photos change
  const ShowCarousel = (photos) => {
    return useMemo(() => <PhotoCarousel media={photos} />, [photos]);
  }

  return (
    <div id='CardFramePostCard'>
      <div id='CardHolder'>

        <div className='PostCardContainer'>
          <div id='NewsCardTop'>
            <div id='NewsCardTopLeft'>
              <div id='ProfImgContainer'>
                {
                  e.user.profile.photoUrl ?
                    <img src={e.user.profile.photoUrl} alt='profile' id='ProfileImg' />
                    :
                    <img src={noUserImage} alt='profile' id='ProfileImg' />
                }
              </div>
              <div id='ProfileName'>{e.user.displayName}</div>
            </div>
            <div id='StatusBar' style={
              e.type === 'selling' ? { backgroundColor: "#07911C" }
                :
                e.type === 'leasing' ? { backgroundColor: "#C89933" } :
                  { backgroundColor: "#833063" }}>
              {e.type}
            </div>
          </div>
          {width < 550 ?
            <div id='DetailCol'>
              <div id='PostCardDetailRow'>
                <div className='Details'>{moment(e.createdAt).format('DD MMM')}</div>
                <div className='Details'>{e.breed.name}</div>
                <div className='Details'>{e.location}</div>
              {/* </div>
              <div id='DetailRow2'> */}
                <div className='Details'>{e.height}hh</div>
                <div className='Details'>Price: {
                  new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(e.price)
                }</div>
              </div>
            </div>
            :
            <div id='PostCardDetailRow'>
              <div className='Details'>{moment(e.createdAt).format('DD MMM')}</div>
              <div className='Details'>{e.breed.name}</div>
              <div className='Details'>{e.location}</div>
              <div className='Details'>{e.height}hh</div>
              <div className='Details'>Price: {
                new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(e.price)
              }</div>
            </div>
          }
          <div id="NewsTitle"> {e.title}</div>
          <div id='NewsDescript'>{e.description}</div>
          <div id="qrCodeWrapper">
            <QRCode qrCodeText={window.location.href} />
          </div>
          <div id='postCardDetailsWrapper'>
            <div className="shareAndSeeMore Details">
              <button onClick={toggleMoreDetails} aria-label="See more details about this ad" className="moreDetails Details">
                <div className="moreDetailsBar" />
                <span className="seeMoreDetails">See More Details</span>
                <span className="chevronSymbol">
                  <i className={`fa fa-chevron-${!showMoreDetails ? 'down' : 'up'}`} aria-hidden="true"></i>
                </span>
              </button>
              {
                showSocialSection &&
                <div className="shareSection">
                  <img src={like} alt="Likes" />
                  <span className="numberOfLikes">{e.likes} {e.likes?.length > 1 ? "likes" : ""}</span>
                  <img className="shareImg" src={share} alt="share" />
                  <span>Share</span>
                </div>
              }
            </div>


            <div className={`moreDetailsSection ${showMoreDetails ? 'animated' : 'hidden'}`}>
              <div className="moreDetailLeft">
                <p className="moreDetailsTitle">Name: <span className="moreDetailsText">{e.name}</span></p>
                <p className="moreDetailsTitle">Age: <span className="moreDetailsText">{e.age}</span></p>
                <p className="moreDetailsTitle">Breed: <span className="moreDetailsText">{e.breed.name}</span></p>
                {
                  e.breedSecondary && e.breedSecondary.length > 0 &&
                  <p className="moreDetailsTitle">
                    Secondary Breed: <span className="moreDetailsText">{e.breedSecondary.map(x => x.name).join(', ')}</span>
                  </p>
                }
                <p className="moreDetailsTitle">Color: <span className="moreDetailsText">{e.color.name}</span></p>
              </div>
              <div className="moreDetailRight">
                <p className="moreDetailsTitle">Temperament: <span className="moreDetailsText">{e.temperament}</span></p>
                <p className="moreDetailsTitle">Gender: <span className="moreDetailsText">{e.gender.name}</span></p>
                <p className="moreDetailsTitle">Discipline: <span className="moreDetailsText">{e.discipline.name}</span></p>
                {
                  e.disciplineSecondary && e.disciplineSecondary.length > 0 &&
                  <p className="moreDetailsTitle">
                    Secondary Breed: <span className="moreDetailsText">{e.disciplineSecondary.map(x => x.name).join(', ')}</span>
                  </p>
                }
              </div>
            </div>
            {children}
          </div>
          <div id='photosHead'>
            <PageTitle title="Photos" />
          </div>
          <div id='photosContainer'>
            {ShowCarousel(e.photos)}
          </div>

        </div>


      </div>



    </div>
  )
}

export default PostCard;
